import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isLogIn } from './App';

export const PublicRoute = () => {
    return isLogIn().isToken ? <Outlet /> : <Navigate to="/" />;
}
export const PrivateRoute = () => {
    return isLogIn().isToken ? <Navigate to="/" /> : <Outlet />;
}
