import { API_BASE_URL } from "../../config";
export const API_URL = API_BASE_URL;

export const SIGNUP = `${API_BASE_URL}/api/users/signup`;
export const GUEST_LOGIN = `${API_BASE_URL}/api/users/guest-login`;
export const LOGIN_USER = `${API_BASE_URL}/api/users/login`;
export const LOGOUT_USER = `${API_BASE_URL}/api/admin-user/logout`;
export const BEST_SELLER = `${API_BASE_URL}/api/products/bestselling`;
export const CONTACT_US = `${API_BASE_URL}/api/contactUs/add`;
export const NEWSLETTER = `${API_BASE_URL}/api/newsletter/`;
export const PRODUCT_DETAIL = `${API_BASE_URL}/api/products/detail`;
export const NAVIGATION_TREE = `${API_BASE_URL}/api/users/navigation-tree`;
export const CUSTOMIZATION_COLORS = `${API_BASE_URL}/api/color/list-color-customization`;
export const ADD_TO_CART = `${API_BASE_URL}/api/cart`;
export const LIST_CART = `${API_BASE_URL}/api/cart`;
export const UPDATE_CART = `${API_BASE_URL}/api/cart/update`;
export const UPLOAD_CUSTOMIZATION_IMAGE = `${API_BASE_URL}/api/utils/upload?type=customization`;
export const DELETE_FROM_CART = `${API_BASE_URL}/api/cart/`;
export const FETCH_USER_PROFILE = `${API_BASE_URL}/api/users/profile`;
export const UPDATE_USER_PROFILE = `${API_BASE_URL}/api/users`;
export const DELETE_USER_ADDRESS = `${API_BASE_URL}/api/users/address`;
export const ADD_USER_ADDRESS = `${API_BASE_URL}/api/users/address`;
export const GET_PRODUCTS_LIST = `${API_BASE_URL}/api/products/category`;
export const FILTER_DETAILS = `${API_BASE_URL}/api/products/filter`;
export const CHECKOUT = `${API_BASE_URL}/api/cart/checkout`;
export const PLACE_ORDER = `${API_BASE_URL}/api/order`;
export const PAYMENT_SUCCESS = `${API_BASE_URL}/api/payment`;
export const FETCH_ORDER_DETAIL = `${API_BASE_URL}/api/order`;
export const CANCEL_ORDER = `${API_BASE_URL}/api/order`;
export const SEARCH = `${API_BASE_URL}/api/products/search`;
export const FORGOT_PASSWORD = `${API_BASE_URL}/api/users/forgot-password`;
export const DELETE_USER = `${API_BASE_URL}/api/users`;
export const UPLOAD_REVIEW_IMAGE = `${API_BASE_URL}/api/utils/upload?type=review`;
export const ADD_REVIEW = `${API_BASE_URL}/api/review/`;
