import React, { useEffect, useRef, useState } from "react";
import useRazorpay from "react-razorpay";
import images from "../../images";
import "./Cart.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Modal } from "react-bootstrap";
import { Mousewheel } from "swiper/modules";
import Product from "../../common/Product";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  addUserAddress,
  checkout,
  fetchOrderDetail,
  paymentSuccess,
  fetchUserProfile,
  listCart,
  placeOrder,
  updateCart,
} from "../../API/fakebackend";
import { API_URL } from "../../API/url";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { formatNumberWithCommas, isLogIn } from "../../../App";
import SetPassword from "../../Auth/SetPassword";
import Signup from "../../Auth/Signup";
import ForgotPassword from "../../Auth/ForgotPassword";
import Login from "../../Auth/Login";
import Swal from "sweetalert2";

const color = [
  { name: "Galaxy Blue", code: "#1E85D3", id: "galaxy_blue" },
  { name: "Maroon", code: "#941826", id: "maroon" },
  { name: "Olive", code: "#32AD9F", id: "olive" },
  { name: "Hot Pink", code: "#E67988", id: "hot_pink" },
  { name: "Navy", code: "#243458", id: "navy" },
];

export const bestseller = [
  {
    name: "Classic Flexi Scrub ",
    category: "Womens",
    price: "₹ 1,500.00",
    image: images.img1,
    rating: "4.8",
    hot: true,
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Lab coats",
    price: "₹ 1,500.00",
    image: images.img2,
    rating: "4.8",
    new: true,
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Womens",
    price: "₹ 1,500.00",
    image: images.img3,
    rating: "4.8",
    new: true,
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Womens",
    price: "₹ 1,500.00",
    image: images.img4,
    rating: "4.8",
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Mens",
    price: "₹ 1,500.00",
    image: images.img5,
    rating: "4.8",
    hot: true,
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Womens",
    price: "₹ 1,500.00",
    image: images.img6,
    rating: "4.8",
    new: true,
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Womens",
    price: "₹ 1,500.00",
    image: images.img7,
    rating: "4.8",
    new: true,
  },
];

export const address = [
  {
    name: "Flat 301, Silver Springs Apartments, Andheri West, Mumbai, Maharashtra - 400053",
    id: "address1",
  },
  {
    name: "C-14 305, ABC Apartment, XYZ lane, Near Landmark, Paramount Area, Mumbai, Maharashtra - 400001",
    id: "address2",
  },
];

export const paymentMethod = [
  // { name: "BHIM/UPI", id: "BHIM/UPI" },
  // { name: "Net banking", id: "Net_banking" },
  // { name: "Credit card", id: "Credit_Card" },
  // { name: "Debit card", id: "Debit_Card" },
  { name: "Online", id: "ONLINE" },
  { name: "Cash on Delivery", id: "Cash_on_Delivery" },
];
export const cancelOrder = [
  { name: "Mistakenly ordered item", id: "mistakenly_ordered_item" },
  { name: "Got another offer", id: "got_another_offer" },
  { name: "Want to order other item", id: "want_to_order_other_item" },
  { name: "Don’t need anymore", id: "dont_need" },
];

const EmptyCart = () => {
  return (
    <div className="empty_cart">
      <div className="mb-2">
        <img src={images.local_mall} alt="" width={34} />
      </div>
      <p className="primary-text font16 fontPrimary2">You Cart is Empty</p>
    </div>
  );
};

export default function Cart() {
  const [Razorpay] = useRazorpay();
  const [count, setcount] = useState(1);
  const [showModalNew, setshowModalNew] = useState(false);
  const [modelType, setmodelType] = useState("");
  const [page, setPage] = useState(1);
  const [cartData, setCartData] = useState();
  const [addressData, setAddressData] = useState();
  const [authUser, setAuthUser] = useState("");
  const [checkoutData, setCheckoutData] = useState();
  const [orderDetail, setOrderDetail] = useState();
  const [companyDetails, SetCompanyDetails] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("page");
  const navigate = useNavigate();
  const [addressFormData, setAddressFormData] = useState({
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    pin_code: "",
    state: "",
    country: "",
    companyName: "",
    companyDetail: companyDetails,
    gstNo: "",
  });

  const [errors, setErrors] = useState({
    address_line_1: false,
    address_line_2: false,
    address_line_3: false,
    pin_code: false,
    state: false,
    country: false,
    companyName: false,
    companyDetail: false,
    gstNo: false,
  });

  const [formData, setFormData] = useState({
    payment_mode: "",
    shippingAddress: {
      _id: "",
      address_line_1: "",
      address_line_2: "",
      address_line_3: "",
      pin_code: "",
      state: "",
      country: "",
      companyName: "",
      companyDetail: false,
      gstNo: "",
    },
    billingAddress: {
      _id: "",
      address_line_1: "",
      address_line_2: "",
      address_line_3: "",
      pin_code: "",
      state: "",
      country: "",
      companyName: "",
      companyDetail: false,
      gstNo: "",
    },
  });

  useEffect(() => {
    setAddressFormData((prevFormData) => ({
      ...prevFormData,
      companyDetail: companyDetails,
    }));
  }, [companyDetails]);

  const handleCheckboxChangeCompany = (e) => {
    SetCompanyDetails(e.target.checked);
  };

  useEffect(() => {
    setAuthUser(JSON.parse(localStorage.getItem("authUser")));
  }, []);

  useEffect(() => {
    if (parseInt(id) === 2) {
      setPage(parseInt(id));
    }
  }, [id]);

  const fetchAddressData = async () => {
    try {
      if (authUser?.data?.user?._id) {
        const response = await fetchUserProfile(authUser?.data?.user?._id);
        setAddressData(response.data.address);
      }
    } catch (error) {
      console.error("Error fetching user profile or address data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await listCart();
      setCartData(response.data);
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (authUser) {
      fetchAddressData();
    }
  }, [authUser]);

  const handleClose = () => {
    setshowModalNew(false);
  };
  const openModel = (type) => {
    setmodelType(type);
    setshowModalNew(true);
  };

  const updateCartItem = async (values) => {
    try {
      const response = await updateCart(values);
      if (response.result === 1) {
        fetchData();
      }
    } catch (error) {
      console.error("Update Error:", error);
    }
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddressFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleAddressSubmit = async () => {
    const newErrors = {};

    if (!addressFormData.address_line_1?.trim()) {
      newErrors.address_line_1 = "Address line 1 is required";
    }

    if (!addressFormData.address_line_2?.trim()) {
      newErrors.address_line_2 = "Address line 2 is required";
    }

    if (!addressFormData.pin_code?.trim()) {
      newErrors.pin_code = "Pincode is required";
    } else if (!/^\d{6}$/.test(addressFormData.pin_code.trim())) {
      newErrors.pin_code = "Pincode should be exactly 6 digits";
    }

    if (!addressFormData.state?.trim()) {
      newErrors.state = "State is required";
    } else if (/\d/.test(addressFormData.state.trim())) {
      newErrors.state = "State should not contain numbers";
    }

    if (!addressFormData.country?.trim()) {
      newErrors.country = "Country is required";
    } else if (/\d/.test(addressFormData.country.trim())) {
      newErrors.country = "Country should not contain numbers";
    }

    if (addressFormData.companyDetail) {
      if (!addressFormData.companyName?.trim()) {
        newErrors.companyName = "Company Name is required";
      }
      if (!addressFormData.gstNo?.trim()) {
        newErrors.gstNo = "GST Number is required";
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await addUserAddress(authUser?.data?.user?._id, {
        address: addressFormData,
      });

      if (response.result === 1) {
        fetchAddressData();
        toast.success("Address Added Successfully!", { autoClose: 1000 });
        handleClose();
      } else {
        toast.error("There was an Error While Adding Address!", {
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error adding address:", error);
      toast.error("Failed to add address. Please try again later.", {
        autoClose: 1000,
      });
    }
  };

  const handleAddressSelection = (address) => {
    setFormData({
      ...formData,
      shippingAddress: {
        _id: address._id,
        address_line_1: address.address_line_1,
        address_line_2: address.address_line_2,
        address_line_3: address.address_line_3,
        pin_code: address.pin_code,
        state: address.state,
        country: address.country,
        companyName: address.companyName,
        companyDetail: companyDetails,
        gstNo: address.gstNo,
      },
    });
  };

  const handleBillingAddressSelection = (address) => {
    setFormData({
      ...formData,
      billingAddress: {
        _id: address._id,
        address_line_1: address.address_line_1,
        address_line_2: address.address_line_2,
        address_line_3: address.address_line_3,
        pin_code: address.pin_code,
        state: address.state,
        country: address.country,
        companyName: address.companyName,
        companyDetail: companyDetails,
        gstNo: address.gstNo,
      },
    });
  };

  const handlePaymentMethodSelection = (paymentMode) => {
    setFormData({
      ...formData,
      payment_mode: paymentMode,
    });
  };
  const initiatePayment = (orderDetails, contactDetails) => {
    const options = {
      key: process.env.REACT_APP_RAZOR_PAY_API_KEY,
      amount: orderDetails.amount,
      currency: "INR",
      name: "Medilinen",
      description: "",
      image: images.logo,
      order_id: orderDetails.id,
      handler: async function (response) {
        try {
          await paymentSuccess({
            razorPayOrderId: response.razorpay_order_id,
            razorPayPaymentId: response.razorpay_payment_id,
          });
          setPage(3);
          toast.success("Order Placed Successfully!", { autoClose: 1000 });
        } catch (error) {
          console.error("Payment success handling failed:", error);
          toast.error("Payment processing failed. Please try again.", {
            autoClose: 2000,
          });
        }
      },
      prefill: {
        name: contactDetails?.name,
        email: contactDetails?.email,
        contact: contactDetails?.phone,
      },
      notes: {
        // address: "",
      },
      theme: {
        color: "#082a6b",
      },
    };

    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });
    rzp1.open();
  };

  const validateForm = () => {
    const errors = {};
    const { shippingAddress } = formData;
    if (!shippingAddress.address_line_1) {
      errors.shippingAddress = "Please Select a Shipping Address";
    }
    if (!shippingAddress.pin_code) {
      errors.shippingAddress = "Please Select a Shipping Address";
    }
    if (!shippingAddress.state) {
      errors.shippingAddress = "Please Select a Shipping Address";
    }
    if (!shippingAddress.country) {
      errors.shippingAddress = "Please Select a Shipping Address";
    }
    const { billingAddress } = formData;
    if (!billingAddress.address_line_1) {
      errors.billingAddress = "Please Select a Billing Address";
    }
    if (!billingAddress.pin_code) {
      errors.billingAddress = "Please Select a Billing Address";
    }
    if (!billingAddress.state) {
      errors.billingAddress = "Please Select a Billing Address";
    }
    if (!billingAddress.country) {
      errors.billingAddress = "Please Select a Billing Address";
    }
    return errors;
  };

  const [addressErrors, setAddressErrors] = useState();

  const addOrder = async () => {
    try {
      // Show the loading Swal
      Swal.fire({
        title: "Processing...",
        text: "Please wait while we process your order.",
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length > 0) {
        Swal.close();

        setAddressErrors(validationErrors);
      } else {
        const response = await placeOrder(formData);

        // Close the loading Swal
        Swal.close();

        if (response?.data?.orderId) {
          const data = await fetchOrderDetail(response.data.orderId);
          setOrderDetail(data.data);
          const orderDetails = response.data;
          const contactDetails = data?.data?.[0]?.contactDetails;
          if (formData.payment_mode === "ONLINE") {
            initiatePayment(orderDetails, contactDetails);
          } else {
            setPage(3);
            toast.success("Order Placed Successfully!", { autoClose: 1000 });
          }
        } else {
          toast.error(response.msg, { autoClose: 1000 });
        }
        setErrors({});
      }
    } catch (error) {
      // Close the loading Swal if there's an error
      Swal.close();

      console.error("Error placing order:", error);
      toast.error(error?.response?.data?.msg, { autoClose: 1000 });
    }
  };

  const handleProceedToOrder = async () => {
    try {
      setPage(2);
    } catch (error) {
      console.error("Checkout Error:", error);
    }
  };

  const Order = async () => {
    try {
      if (page === 2) {
        const response = await checkout();
        setCheckoutData(response.data);
      }
    } catch (error) {
      console.error("Error during checkout:", error);
    }
  };
  useEffect(() => {
    Order();
  }, [page]);

  const searchParams = new URLSearchParams(window.location.search);
  const setParam = (key, value) => {
    searchParams.set(key, value);
    const to = {
      pathname: window.location.pathname,
      search: searchParams.toString(),
    };
    navigate(to, { replace: true });
  };
  const deleteParam = (key) => {
    searchParams.delete(key);
    const to = {
      pathname: window.location.pathname,
      search: searchParams.toString(),
    };
    navigate(to, { replace: true });
  };
  const [showSearch, setshowSearch] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [cartOpen, setcartOpen] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const handleCloseNew = () => {
    setshowModal(false);
    setOpen(false);
    setcartOpen(false);
    deleteParam("type");
  };

  const handleClick = (type) => {
    setParam("type", type);
    setTimeout(() => {
      setOpen(true);
    }, 1000);
  };

  return (
    <>
      <section className="cartDtlSctin ">
        <div className="container">
          <div className="cartDtlSctin__row row">
            <div className="col-md-8 col-lg-9">
              {page === 3 && (
                <div className="orderStatusBox">
                  <div className="orderStatusBox__top d-flex">
                    <div className="orderStatusBox__topIcon">
                      <img src={images.greenCheck} alt="" />
                    </div>
                    <div className="orderStatusBox__topContent">
                      <div className="font20 bold text-dark">
                        Order placed Successfully
                      </div>
                      <div className="font14 med">
                        Order no.{" "}
                        {orderDetail && orderDetail?.[0]?.order_number}
                      </div>
                    </div>
                  </div>
                  {/* <div className="orderStatusBox__bottom d-flex">
                    <button className="orderStatusBox__button">
                      {" "}
                      <img src={images.download} /> Download invoice Slip
                    </button>
                    <button
                      className="orderStatusBox__button"
                      onClick={() => openModel("trackOrder")}
                    >
                      {" "}
                      <img src={images.distance} /> Track order
                    </button>
                    <button
                      className="orderStatusBox__button cancel"
                      onClick={() => openModel("cancelOrder")}
                    >
                      {" "}
                      <img src={images.redCross} /> Cancel order
                    </button>
                  </div> */}
                </div>
              )}
              {/* {page === 3 && (
                <div className="orderStatusBox">
                  <div className="orderStatusBox__top d-flex">
                    <div className="orderStatusBox__topIcon">
                      <img src={images.block} alt="" />
                    </div>
                    <div className="orderStatusBox__topContent">
                      <div className="font20 bold text-dark">
                        Order placed Successfully
                      </div>
                      <div className="font14 med">
                        Order no. 987654322108568
                      </div>
                    </div>
                  </div>
                  <div className="orderStatusBox__bottom d-flex">
                    <Link to="/my-order" className="orderStatusBox__button">
                      View My Orders
                    </Link>
                  </div>
                </div>
              )}{" "} */}
              {page === 1 && (
                <>
                  {cartData && cartData.total_quantity !== 0 ? (
                    <>
                      <div className="cartDtlSctin__headingholder">
                        <h6 className="cartDtlSctin__heading font16 primary-text bold">
                          Your Cart
                        </h6>
                      </div>
                      <div className="cartTable">
                        {cartData &&
                          cartData?.data.map((item, index) => (
                            <>
                              <div className="cartTable__list  d-flex flex-wrap">
                                <div className="cartTable__count">
                                  {index + 1}
                                </div>
                                <div className="cartTable__preduct d-flex">
                                  <div className="cartTable__preductImg">
                                    <div className="cart_item_img">
                                      <img
                                        src={`${API_URL}/public${item?.variation?.[0].images?.[0]}`}
                                        alt=""
                                        className="img-fluid w-100"
                                      />
                                    </div>
                                  </div>
                                  <div className="cartTable__preductCntnt ">
                                    <div className="cart_item_info">
                                      <h5 className="font16 bold mb-1 fontPrimary2 text-dark">
                                        {item.productName}
                                      </h5>
                                      <p className="font14">{item.gender} </p>
                                      <div className="font14 med mt-2">
                                        <span
                                          className="color_dot me-2"
                                          style={{
                                            backgroundColor: item.color,
                                          }}
                                        ></span>
                                        {`${
                                          item.size_title ? item.size_title : ""
                                        }${
                                          item.size_title
                                            ? ""
                                            : item.size_title_top ||
                                              item.size_title_bottom
                                            ? `Top: ${
                                                item.size_title_top || ""
                                              }, Bottom: ${
                                                item.size_title_bottom || ""
                                              }`
                                            : ""
                                        }`}
                                      </div>
                                      <div className="cart_quantity mt-2">
                                        <div className="qty-input">
                                          <button
                                            className="qty-count qty-count--minus"
                                            data-action="minus"
                                            type="button"
                                            onClick={() =>
                                              updateCartItem({
                                                itemId: item._id,
                                                quantity: -1,
                                              })
                                            }
                                            disabled={item.quantity === 1}
                                          >
                                            -
                                          </button>
                                          <input
                                            className="product-qty"
                                            type="number"
                                            name="product-qty"
                                            min="0"
                                            max="10"
                                            value={item.quantity}
                                            readOnly
                                          />
                                          <button
                                            className="qty-count qty-count--add"
                                            data-action="add"
                                            onClick={() =>
                                              updateCartItem({
                                                itemId: item._id,
                                                quantity: 1,
                                              })
                                            }
                                            type="button"
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="cartTable__price">
                                  ₹{formatNumberWithCommas(item.subtotal)}
                                </div>
                                {(item.customization === true &&
                                  item.customizations?.fontStyle) ||
                                item.customizations?.fontColor ||
                                item.customizations?.text ||
                                item.customizations?.logoUrl ? (
                                  <>
                                    <div className="mb-1">Customization:</div>
                                    <div className="selectedLogoDetails__left d-flex align-items-center">
                                      <div className="d-flex align-items-center flex-grow-1">
                                        {item.customizations.logoUrl && (
                                          <div className="img">
                                            <img
                                              src={`${API_URL}/public${item.customizations?.logoUrl}`}
                                              alt="Logo Preview"
                                            />
                                          </div>
                                        )}
                                        <div
                                          className={`content ${
                                            item.customizations.logoUrl
                                              ? "ml-2 ms-2"
                                              : ""
                                          }`}
                                        >
                                          <div className="name font16 med text-dark">
                                            {item.customizations?.text}
                                          </div>
                                          <div className="desc font12 primary-text">
                                            {item.customizations?.fontColor},{" "}
                                            {item.customizations?.fontStyle}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-end ml-auto">
                                        <h5 className="font16 bold fontPrimary2 text-dark mb-0">
                                          ₹
                                          {formatNumberWithCommas(
                                            item.customizationPrice
                                          )}
                                        </h5>
                                      </div>
                                    </div>
                                  </>
                                ) : null}{" "}
                              </div>
                            </>
                          ))}
                      </div>
                      <div className="login_modal_header mt-3 mb-3">
                        <p className="font14 med">
                          {cartData && cartData.total_quantity} Items
                        </p>
                        <div className="text-end">
                          <h5 className="font16 bold fontPrimary2 text-dark mb-0">
                            ₹
                            {formatNumberWithCommas(cartData && cartData.total)}
                          </h5>
                        </div>
                      </div>
                    </>
                  ) : (
                    <EmptyCart />
                  )}
                </>
              )}
              {page === 2 && (
                <>
                  <div className="cartDtlSctin__headingholder">
                    <h6 className="cartDtlSctin__heading font16 primary-text bold">
                      Your Orders
                    </h6>
                  </div>
                  <div className="cartTable">
                    {checkoutData &&
                      checkoutData?.items?.map((item, index) => (
                        <div className="cartTable__list  d-flex flex-wrap">
                          <div className="cartTable__count">{index + 1}</div>
                          <div className="cartTable__preduct d-flex">
                            <div className="cartTable__preductImg">
                              <div className="cart_item_img">
                                <img
                                  src={`${API_URL}/public${item?.variation?.images?.[0]}`}
                                  alt=""
                                  className="img-fluid w-100"
                                />
                              </div>
                            </div>
                            <div className="cartTable__preductCntnt ">
                              <div className="cart_item_info">
                                <h5 className="font16 bold mb-1 fontPrimary2 text-dark">
                                  {item.product_name} ({item.quantity}x)
                                </h5>
                                <p className="font14">{item.gender} </p>
                                <div className="font14 med mt-2">
                                  <span
                                    className="color_dot me-2"
                                    style={{
                                      backgroundColor: item.color_hexacode,
                                    }}
                                  ></span>
                                  {`${
                                    item.size.length
                                      ? item.size[0].size_title
                                      : ""
                                  }${
                                    item.size_top.length ||
                                    item.size_bottom.length
                                      ? `Top: ${
                                          item.size_top.length
                                            ? item.size_top[0].size_title
                                            : ""
                                        }, Bottom: ${
                                          item.size_bottom.length
                                            ? item.size_bottom[0].size_title
                                            : ""
                                        }`
                                      : ""
                                  }`}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="cartTable__price">
                            ₹{formatNumberWithCommas(item.subtotal)}
                          </div>
                          {(item.customization === true &&
                            item.customizations?.fontStyle) ||
                          item.customizations?.fontColor ||
                          item.customizations?.text ||
                          item.customizations?.logoUrl ? (
                            <>
                              <div className="mb-1">Customization:</div>
                              <div className="selectedLogoDetails__left d-flex align-items-center">
                                <div className="d-flex align-items-center flex-grow-1">
                                  {item.customizations.logoUrl && (
                                    <div className="img">
                                      <img
                                        src={`${API_URL}/public${item.customizations?.logoUrl}`}
                                        alt="Logo Preview"
                                      />
                                    </div>
                                  )}
                                  <div
                                    className={`content ${
                                      item.customizations.logoUrl
                                        ? "ml-2 ms-2"
                                        : ""
                                    }`}
                                  >
                                    <div className="name font16 med text-dark">
                                      {item.customizations?.text}
                                    </div>
                                    <div className="desc font12 primary-text">
                                      {item.customizations?.fontColor},{" "}
                                      {item.customizations?.fontStyle}
                                    </div>
                                  </div>
                                </div>
                                <div className="text-end ml-auto">
                                  <h5 className="font16 bold fontPrimary2 text-dark mb-0">
                                    ₹
                                    {formatNumberWithCommas(
                                      item.customizationPrice
                                    )}
                                  </h5>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      ))}
                  </div>
                  <div className="login_modal_header mt-3 mb-3">
                    <p className="font14 med">
                      {/* {checkoutData && cartData.total_quantity} Items */}
                    </p>
                    <div className="text-end">
                      <h5 className="font16 bold fontPrimary2 text-dark mb-0">
                        ₹
                        {formatNumberWithCommas(
                          checkoutData && checkoutData.total
                        )}
                      </h5>
                    </div>
                  </div>
                </>
              )}
              {page === 3 && (
                <>
                  <div className="cartDtlSctin__headingholder">
                    <h6 className="cartDtlSctin__heading font16 primary-text bold">
                      Your Orders
                    </h6>
                  </div>
                  <div className="cartTable">
                    {orderDetail &&
                      orderDetail?.[0].items?.map((item, index) => (
                        <div className="cartTable__list  d-flex flex-wrap">
                          <div className="cartTable__count">{index + 1}</div>
                          <div className="cartTable__preduct d-flex">
                            <div className="cartTable__preductImg">
                              <div className="cart_item_img">
                                <img
                                  src={`${API_URL}/public${item?.variation?.images?.[0]}`}
                                  alt=""
                                  className="img-fluid w-100"
                                />
                              </div>
                            </div>
                            <div className="cartTable__preductCntnt ">
                              <div className="cart_item_info">
                                <h5 className="font16 bold mb-1 fontPrimary2 text-dark">
                                  {item.product_name} ({item.quantity}x)
                                </h5>
                                <p className="font14">{item.gender} </p>
                                <div className="font14 med mt-2">
                                  <span
                                    className="color_dot me-2"
                                    style={{
                                      backgroundColor: item.color_hexacode,
                                    }}
                                  ></span>
                                  {`${
                                    item.size.length
                                      ? item.size[0].size_title
                                      : ""
                                  }${
                                    item.size_top.length ||
                                    item.size_bottom.length
                                      ? `Top: ${
                                          item.size_top.length
                                            ? item.size_top[0].size_title
                                            : ""
                                        }, Bottom: ${
                                          item.size_bottom.length
                                            ? item.size_bottom[0].size_title
                                            : ""
                                        }`
                                      : ""
                                  }`}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="cartTable__price">
                            ₹{formatNumberWithCommas(item.subtotal)}
                          </div>
                          {(item.customization === true &&
                            item.customizations?.fontStyle) ||
                          item.customizations?.fontColor ||
                          item.customizations?.text ||
                          item.customizations?.logoUrl ? (
                            <>
                              <div className="mb-1">Customization:</div>
                              <div className="selectedLogoDetails__left d-flex align-items-center">
                                <div className="d-flex align-items-center flex-grow-1">
                                  {item.customizations.logoUrl && (
                                    <div className="img">
                                      <img
                                        src={`${API_URL}/public${item.customizations?.logoUrl}`}
                                        alt="Logo Preview"
                                      />
                                    </div>
                                  )}
                                  <div
                                    className={`content ${
                                      item.customizations.logoUrl
                                        ? "ml-2 ms-2"
                                        : ""
                                    }`}
                                  >
                                    <div className="name font16 med text-dark">
                                      {item.customizations?.text}
                                    </div>
                                    <div className="desc font12 primary-text">
                                      {item.customizations?.fontColor},{" "}
                                      {item.customizations?.fontStyle}
                                    </div>
                                  </div>
                                </div>
                                <div className="text-end ml-auto">
                                  <h5 className="font16 bold fontPrimary2 text-dark mb-0">
                                    ₹
                                    {formatNumberWithCommas(
                                      item.customizationPrice
                                    )}
                                  </h5>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      ))}
                  </div>
                  <div className="login_modal_header mt-3 mb-3">
                    <p className="font14 med">
                      {/* {checkoutData && cartData.total_quantity} Items */}
                    </p>
                    <div className="text-end">
                      <h5 className="font16 bold fontPrimary2 text-dark mb-0">
                        ₹
                        {formatNumberWithCommas(
                          checkoutData && checkoutData.total
                        )}
                      </h5>
                    </div>
                  </div>
                </>
              )}
              <hr />
              {page === 2 && (
                <>
                  <div className="cartSection">
                    <div className="cartDtlSctin__headingholder">
                      <h6 className="cartDtlSctin__heading font16 primary-text bold">
                        Billing Address
                      </h6>
                    </div>
                    <div className="cartAddressRow d-flex flex-wrap">
                      {addressData &&
                        addressData.map((data, index) => (
                          <div key={index} className="cartAddressBox">
                            <input
                              type="radio"
                              name="billingAddress"
                              id={`billing_${data._id}`}
                              checked={formData.billingAddress._id === data._id}
                              onChange={() =>
                                handleBillingAddressSelection(data)
                              }
                            />
                            <label htmlFor={`billing_${data._id}`}>
                              {data.address_line_1}, {data.address_line_2},{" "}
                              {data.address_line_3}, {data.state},{" "}
                              {data.pin_code}, {data.country}
                              {data.companyDetail && (
                                <>
                                  , {data.companyName}, ({data.gstNo})
                                </>
                              )}
                            </label>
                          </div>
                        ))}
                    </div>
                    {addressErrors?.billingAddress && (
                      <p className="text-danger mt-2">
                        {addressErrors?.billingAddress}
                      </p>
                    )}
                  </div>

                  <div className="cartSection">
                    <div className="cartDtlSctin__headingholder">
                      <h6 className="cartDtlSctin__heading font16 primary-text bold">
                        Shipping Address
                      </h6>
                    </div>
                    <div className="cartAddressRow d-flex flex-wrap">
                      {addressData &&
                        addressData.map((data, index) => (
                          <div key={index} className="cartAddressBox">
                            <input
                              type="radio"
                              name="shippingAddress"
                              id={`shipping_${data._id}`}
                              checked={
                                formData.shippingAddress._id === data._id
                              }
                              onChange={() => handleAddressSelection(data)}
                            />
                            <label htmlFor={`shipping_${data._id}`}>
                              {data.address_line_1}, {data.address_line_2},{" "}
                              {data.address_line_3}, {data.state},{" "}
                              {data.pin_code}, {data.country}
                              {data.companyDetail && (
                                <>
                                  , {data.companyName}, ({data.gstNo})
                                </>
                              )}
                            </label>
                          </div>
                        ))}
                    </div>
                    {addressErrors?.shippingAddress && (
                      <p className="text-danger mt-2">
                        {addressErrors?.shippingAddress}
                      </p>
                    )}
                  </div>
                  <div>
                    <hr></hr>
                    <button
                      onClick={() => openModel("addaddress")}
                      className="cartAddressBox cartAddressBox-addMore d-flex justify-content-center align-items-center"
                    >
                      <div className="d-flex">
                        <img src={images.add_circle} alt="" />
                        <span className="font-14 med primary-text">
                          {" "}
                          Add New Address
                        </span>
                      </div>
                    </button>
                  </div>
                  <div className="cartSection">
                    <div className="cartDtlSctin__headingholder">
                      <h6 className="cartDtlSctin__heading font16 primary-text bold">
                        Payment
                      </h6>
                    </div>
                    <div className="radioType2 d-flex flex-column">
                      {paymentMethod.map((data, i) => (
                        <div key={i} className="radioType2__box">
                          <input
                            type="radio"
                            id={data.id}
                            name="paymentMode"
                            checked={formData.payment_mode === data.id}
                            onChange={() =>
                              handlePaymentMethodSelection(data.id)
                            }
                          />
                          <label htmlFor={data.id}>{data.name}</label>
                        </div>
                      ))}
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-content-center">
                      <div className="font14 bold fontPrimary2">
                        ₹{formatNumberWithCommas(cartData && cartData.total)}
                      </div>
                      <button
                        className="common_btn primary btn2"
                        onClick={() => {
                          addOrder();
                        }}
                      >
                        Place order
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
            {page === 1 && (
              <div className="col-md-4 col-lg-3 position-relative">
                <div className="cartTotalbox">
                  <div className="font16 primary-text bold">
                    Subtotal payment
                  </div>
                  <div className="cartTotalbox__middle">
                    {cartData &&
                      cartData.data.map((item, index) => (
                        <div className="cartTotalbox__list d-flex justify-content-between align-items-center">
                          <div className="left med font12 primary-text">
                            {item.productName} ({item.quantity})
                          </div>
                          <div className="right med font12 text-dark">
                            ₹{formatNumberWithCommas(item.total)}
                          </div>
                        </div>
                      ))}

                    {/* <div className="cartTotalbox__list d-flex justify-content-between align-items-center">
                    <div className="left med font12 primary-text">
                      Discount (10%)
                    </div>
                    <div className="right med font12 text-dark">₹2,125.00</div>
                  </div> */}
                  </div>
                  <hr />
                  <div className="cartTotalbox__total  d-flex justify-content-between">
                    <div className="left bold font16 primary-text">Total</div>
                    <div className="right">
                      <div className="bold font16 text-dark">
                        ₹{formatNumberWithCommas(cartData && cartData?.total)}
                      </div>
                      <div className="med font12 text-red">
                        Inclusive of all taxes
                      </div>
                    </div>
                  </div>
                  {page === 1 && cartData && cartData.total_quantity !== 0 && (
                    <>
                      {isLogIn().isToken ? (
                        <button
                          className="buttonHolder__button buttonHolder__blue"
                          onClick={handleProceedToOrder}
                        >
                          Proceed to Order
                        </button>
                      ) : (
                        <button
                          className="buttonHolder__button buttonHolder__blue"
                          onClick={() => handleClick("login")}
                        >
                          Proceed to Order
                        </button>
                      )}
                      {/* {!isLogIn().isToken && searchParams.get("type") ? (
                        <>
                          {searchParams.get("type") == "set-password" ? (
                            <SetPassword
                              show={open}
                              handleClick={handleClick}
                            />
                          ) : searchParams.get("type") == "signup" ? (
                            <Signup
                              show={open}
                              handleClick={handleClick}
                              handleClose={handleCloseNew}
                            />
                          ) : searchParams.get("type") == "forgot" ? (
                            <ForgotPassword
                              show={open}
                              handleClick={handleClick}
                            />
                          ) : (
                            <Login
                              show={open}
                              handleClose={handleCloseNew}
                              handleClick={handleClick}
                            />
                          )}
                        </>
                      ) : null} */}
                    </>
                  )}
                </div>
              </div>
            )}

            {page === 2 && (
              <div className="col-md-4 col-lg-3 position-relative">
                <div className="cartTotalbox">
                  <div className="font16 primary-text bold">
                    Subtotal payment
                  </div>

                  <div className="cartTotalbox__middle">
                    {checkoutData &&
                      checkoutData?.items?.map((item, index) => (
                        <div className="cartTotalbox__list d-flex justify-content-between align-items-center">
                          <div className="left med font12 primary-text">
                            {item.product_name} ({item.quantity})
                          </div>
                          <div className="right med font12 text-dark">
                            ₹{formatNumberWithCommas(item.total)}
                          </div>
                        </div>
                      ))}

                    {/* <div className="cartTotalbox__list d-flex justify-content-between align-items-center">
                    <div className="left med font12 primary-text">
                      Discount (10%)
                    </div>
                    <div className="right med font12 text-dark">₹2,125.00</div>
                  </div> */}
                  </div>
                  <hr />
                  <div className="cartTotalbox__total  d-flex justify-content-between">
                    <div className="left bold font16 primary-text">Total</div>
                    <div className="right">
                      <div className="bold font16 text-dark">
                        ₹
                        {formatNumberWithCommas(
                          checkoutData && checkoutData?.total
                        )}
                      </div>
                      <div className="med font12 text-red">
                        Inclusive of all taxes
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      {/* <section className="common_sec">
        <div className="container">
          <div className="heading_block">
            <h6 className="mb-0 fontPrimary2 text-dark">
              Relevant to this product
            </h6>
          </div>
        </div>
        <div className="ps-4">
          <div className="bestseller_slider">
            <Swiper
              spaceBetween={15}
              slidesPerView={1.4}
              loop={false}
              modules={[Mousewheel]}
              breakpoints={{
                1299: {
                  slidesPerView: 5.8,
                },
                992: {
                  slidesPerView: 4.3,
                },
                768: {
                  slidesPerView: 3.3,
                },
                575: {
                  slidesPerView: 2.3,
                },
              }}
            >
              {bestseller.map((opt, i) => {
                return (
                  <SwiperSlide key={i}>
                    <Product data={opt} color={color} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section> */}

      <Modal show={showModalNew} onHide={handleClose} centered size="md">
        <Modal.Header closeButton className="">
          <Modal.Title>
            <h5 className="mb-0 font20 fontPrimary2 text-dark">
              {modelType === "addaddress" ? <>Add Address</> : null}
              {modelType === "cancelOrder" ? <>Cancel order</> : null}
              {modelType === "trackOrder" ? <>Track order</> : null}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modelType === "addaddress" ? (
            <>
              <div className="mb-3">
                <input
                  type="text"
                  className={`formField w-100 ${
                    errors.address_line_1 ? "error" : ""
                  }`}
                  placeholder="Address line 1"
                  name="address_line_1"
                  value={addressFormData.address_line_1}
                  onChange={handleAddressChange}
                />
                {errors.address_line_1 && (
                  <span className="text-danger">
                    Address Line 1 is required
                  </span>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className={`formField w-100 ${
                    errors.address_line_2 ? "error" : ""
                  }`}
                  placeholder="Address line 2"
                  name="address_line_2"
                  value={addressFormData.address_line_2}
                  onChange={handleAddressChange}
                />
                {errors.address_line_2 && (
                  <span className="text-danger">
                    Address Line 2 is required
                  </span>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className={`formField w-100 ${
                    errors.address_line_3 ? "error" : ""
                  }`}
                  placeholder="Address line 3"
                  name="address_line_3"
                  value={addressFormData.address_line_3}
                  onChange={handleAddressChange}
                />
                {errors.address_line_3 && (
                  <span className="text-danger">
                    Address Line 3 is required
                  </span>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className={`formField w-100 ${
                    errors.pin_code ? "error" : ""
                  }`}
                  placeholder="Pincode"
                  name="pin_code"
                  value={addressFormData.pin_code}
                  onChange={handleAddressChange}
                />
                {errors.pin_code && (
                  <span className="text-danger">Pincode is required</span>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className={`formField w-100 ${errors.state ? "error" : ""}`}
                  placeholder="State"
                  name="state"
                  value={addressFormData.state}
                  onChange={handleAddressChange}
                />
                {errors.state && (
                  <span className="text-danger">State is required</span>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className={`formField w-100 ${errors.country ? "error" : ""}`}
                  placeholder="Country"
                  name="country"
                  value={addressFormData.country}
                  onChange={handleAddressChange}
                />
                {errors.country && (
                  <span className="text-danger">Country is required</span>
                )}
              </div>
              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="termsCheck"
                  checked={companyDetails}
                  onChange={handleCheckboxChangeCompany}
                />
                <label className="form-check-label" htmlFor="termsCheck">
                  Add company details (optional)
                </label>
              </div>
              {companyDetails === true && (
                <>
                  <div className="mb-3">
                    <input
                      type="text"
                      className={`formField w-100 ${
                        errors.companyName ? "error" : ""
                      }`}
                      placeholder="Company Name"
                      name="companyName"
                      value={addressFormData.companyName}
                      onChange={handleAddressChange}
                    />
                    {errors.companyName && (
                      <span className="text-danger">
                        Company Name is required
                      </span>
                    )}
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className={`formField w-100 ${
                        errors.gstNo ? "error" : ""
                      }`}
                      placeholder="GST Number"
                      name="gstNo"
                      value={addressFormData.gstNo}
                      onChange={handleAddressChange}
                    />
                    {errors.gstNo && (
                      <span className="text-danger">
                        GST Number is required
                      </span>
                    )}
                  </div>
                </>
              )}

              <div className="mb-3">
                <button
                  className="w-100 common_btn primary btn2 text-center"
                  onClick={handleAddressSubmit}
                >
                  Add
                </button>
              </div>
            </>
          ) : null}

          {modelType === "cancelOrder" ? (
            <>
              <div className="font14 text-light2 mb-4">
                Are you sure you want to cancel your order?
              </div>

              <div className="font14 text-light2 mb-1">
                Reason for cancellation
              </div>
              <div className="radioType2 d-flex flex-column">
                {cancelOrder.map((data, i) => {
                  return (
                    <div key={i} className="radioType2__box">
                      <input type="radio" id={data.id} name="color" />
                      <label htmlFor={data.id}>{data.name}</label>
                    </div>
                  );
                })}
              </div>
              <div className="text-center mt-3">
                <button className="common_btn primary btn2">
                  Cancel Order
                </button>
              </div>
            </>
          ) : null}

          {modelType === "trackOrder" ? (
            <>
              <div className="trackOrderbox">
                <div className="trackOrderList trackOrderListHeader d-flex justify-content-between align-items-start">
                  <div className="left text-dark font16 med">
                    Order no. 987654322108568
                  </div>
                  <div className="right">
                    <button className="trackOrderbox__refreshButton">
                      <img src={images.refresh} alt="" />
                      <span>Refresh</span>
                    </button>{" "}
                  </div>
                </div>
                <div className="trackOrderList d-flex align-items-start">
                  <div className="left text-light2 font14 med">
                    04 May, 2024
                  </div>
                  <div className="right text-dark med">Placed order</div>
                </div>
                <div className="trackOrderList d-flex align-items-start">
                  <div className="left text-light2 font14 med">
                    04 May, 2024
                  </div>
                  <div className="right text-dark med">
                    <div className="text-dark mb-1">Track your Shipment </div>
                    <div className="text-light2 mb-1">
                      Consignment no. is{" "}
                      <span className="text-dark">135416843514689464.</span>
                    </div>
                    <div className="text-light2">
                      Track your shipment on{" "}
                      <a href="www.delhivery.com" target="_blank">
                        www.delhivery.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
}
