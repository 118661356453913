import axios from "axios";
import { del, get, post, postWithFile, put } from "./api_helper";
import * as url from "./url";

export const signUp = (user) => post(`${url.SIGNUP}`, user);
export const guestLogin = (user) => post(`${url.GUEST_LOGIN}`, user);
export const loginUser = (user) => post(`${url.LOGIN_USER}`, user);
export const logout = (user) => post(`${url.LOGOUT_USER}`, user);
export const navigationTree = () => get(`${url.NAVIGATION_TREE}`);
export const productDetail = (id) => get(`${url.PRODUCT_DETAIL}/${id}`);
export const bestSeller = () => get(`${url.BEST_SELLER}`);
export const customizationColors = () => get(`${url.CUSTOMIZATION_COLORS}`);
export const contactUs = (values) => post(`${url.CONTACT_US}`, values);
export const newsLetter = (values) => post(`${url.NEWSLETTER}`, values);
export const addToCart = (values) => post(`${url.ADD_TO_CART}`, values);
export const listCart = () => get(`${url.LIST_CART}`);
export const updateCart = (values) => put(`${url.UPDATE_CART}`, values);
export const uploadCustomizationImage = (uploadFile) =>
  postWithFile(`${url.UPLOAD_CUSTOMIZATION_IMAGE}`, uploadFile);
export const deleteFromCart = (values) =>
  del(`${url.DELETE_FROM_CART}`, values);
export const fetchUserProfile = (id) => get(`${url.FETCH_USER_PROFILE}/${id}`);
export const updateUserProfile = (id, values) =>
  put(`${url.UPDATE_USER_PROFILE}/${id}`, values);
export const addUserAddress = (id, values) =>
  post(`${url.ADD_USER_ADDRESS}/${id}`, values);
export const deleteAddress = (id, values) =>
  del(`${url.DELETE_USER_ADDRESS}/${id}`, values);
export const getProductsList = (id) => get(`${url.GET_PRODUCTS_LIST}/${id}`);
export const filterDetails = () => get(`${url.FILTER_DETAILS}`);
export const placeOrder = (values) => post(`${url.PLACE_ORDER}`, values);
export const paymentSuccess = (values) => post(`${url.PAYMENT_SUCCESS}`, values);
export const checkout = () => get(`${url.CHECKOUT}`);
export const fetchOrderDetail = (id) => get(`${url.FETCH_ORDER_DETAIL}/${id}`);
export const cancelOrderAPI = (values) => put(`${url.CANCEL_ORDER}`, values);
export const searchItem = (values) => get(`${url.SEARCH}/${values}`);
export const forgotPassword = (values) =>
  post(`${url.FORGOT_PASSWORD}`, values);
export const deleteUser = (id) => del(`${url.DELETE_USER}/${id}`);
export const uploadReviewImage = (uploadFile) =>
  postWithFile(`${url.UPLOAD_REVIEW_IMAGE}`, uploadFile);
export const addReview = (values) => post(`${url.ADD_REVIEW}`, values);
