import React, { useEffect, useState } from "react";
import images from "../../images";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import { addUserAddress } from "../../API/fakebackend";
import Swal from "sweetalert2";

export default function Address(props) {
  const [edit, setEdit] = useState(false);
  const [active, setActive] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [companyDetail, SetCompanyDetails] = useState(false);
  const [showModalNew, setshowModalNew] = useState(false);
  const [modelType, setmodelType] = useState("");
  const [authUser, setAuthUser] = useState("");

  const [errors, setErrors] = useState({
    address_line_1: false,
    address_line_2: false,
    address_line_3: false,
    pin_code: false,
    state: false,
    country: false,
    companyName: false,
    companyDetail: false,
    gstNo: false,
  });

  useEffect(() => {
    setAuthUser(JSON.parse(localStorage.getItem("authUser")));
  }, []);

  const [addressFormData, setAddressFormData] = useState({
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    pin_code: "",
    state: "",
    country: "",
    companyName: "",
    companyDetail: companyDetail,
    gstNo: "",
  });

  useEffect(() => {
    setAddressFormData((prevData) => ({
      ...prevData,
      companyDetail: companyDetail,
    }));
  }, [companyDetail]);

  const handleClose = () => {
    setshowModalNew(false);
  };
  const openModel = (type) => {
    setmodelType(type);
    setshowModalNew(true);
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddressFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleAddressSubmit = async () => {
    const newErrors = {};

    if (!addressFormData.address_line_1?.trim()) {
      newErrors.address_line_1 = "Address line 1 is required";
    }

    if (!addressFormData.address_line_2?.trim()) {
      newErrors.address_line_2 = "Address line 2 is required";
    }

    if (!addressFormData.pin_code?.trim()) {
      newErrors.pin_code = "Pincode is required";
    } else if (!/^\d{6}$/.test(addressFormData.pin_code.trim())) {
      newErrors.pin_code = "Pincode should be exactly 6 digits";
    }

    if (!addressFormData.state?.trim()) {
      newErrors.state = "State is required";
    } else if (/\d/.test(addressFormData.state.trim())) {
      newErrors.state = "State should not contain numbers";
    }

    if (!addressFormData.country?.trim()) {
      newErrors.country = "Country is required";
    } else if (/\d/.test(addressFormData.country.trim())) {
      newErrors.country = "Country should not contain numbers";
    }

    if (addressFormData.companyDetail) {
      if (!addressFormData.companyName) {
        newErrors.companyName = "Company Name is required";
      }
      if (!addressFormData.gstNo) {
        newErrors.gstNo = "GST Number is required";
      }
    }

    if (!addressFormData.country?.trim()) {
      newErrors.country = "Country is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await addUserAddress(authUser?.data?.user?._id, {
        address: addressFormData,
      });

      if (response.result === 1) {
        // fetchAddressData();
        props.onSubmission();
        toast.success("Address Added Successfully!", { autoClose: 1000 });
        handleClose();
      } else {
        toast.error("There was an Error While Adding Address!", {
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error adding address:", error);
      toast.error("Failed to add address. Please try again later.", {
        autoClose: 1000,
      });
    }
  };

  const handleCheckboxChangeCompany = (e) => {
    SetCompanyDetails(e.target.checked);
    handleInputChange("companyDetail", e.target.checked);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.onDelete(id);
        Swal.fire("Deleted!", "Your Address has been deleted.", "success");
      }
    });
  };

  const editAddress = (addr, index) => {
    setActive({
      id: addr._id,
      name: `Address ${index + 1}`,
      address_line_1: addr.address_line_1,
      address_line_2: addr.address_line_2,
      address_line_3: addr.address_line_3,
      pin_code: addr.pin_code,
      state: addr.state,
      country: addr.country,
      companyName: addr.companyName,
      gstNo: addr.gstNo,
      companyDetail: addr.companyDetail,
    });
    setActiveIndex(index);
    setEdit(true);
  };

  const handleInputChange = (field, value) => {
    const updatedActive = {
      ...active,
      [field]: value,
    };
    setActive(updatedActive);
    props.onChange(activeIndex, updatedActive);
  };

  const cancelEdit = () => {
    props.change(!props.changeData);
    setEdit(false);
    setActive({});
    setActiveIndex(null);
  };

  return (
    <div className="profile_detail">
      {edit ? (
        <div className="detail_form">
          <button
            className="bold font16 fontPrimary2 text-dark mb-4"
            onClick={cancelEdit}
          >
            <img src={images.arrow_back} alt="" className="me-3" />
            {active?.name}
          </button>
          <div className="form-group mb-2">
            <label>Address Line 1</label>
            <div className="form-input">
              <input
                type="text"
                className="formField  w-100"
                placeholder="Enter address line 1"
                value={active?.address_line_1}
                onChange={(e) =>
                  handleInputChange("address_line_1", e.target.value)
                }
              />
            </div>
          </div>
          <div className="form-group mb-2">
            <label>Address Line 2</label>
            <div className="form-input">
              <input
                type="text"
                className="formField  w-100"
                placeholder="Enter address line 2"
                value={active?.address_line_2}
                onChange={(e) =>
                  handleInputChange("address_line_2", e.target.value)
                }
              />
            </div>
          </div>
          <div className="form-group mb-2">
            <label>Address Line 3</label>
            <div className="form-input">
              <input
                type="text"
                className="formField  w-100"
                placeholder="Enter address line 3"
                value={active?.address_line_3}
                onChange={(e) =>
                  handleInputChange("address_line_3", e.target.value)
                }
              />
            </div>
          </div>
          <div className="form-group mb-2">
            <label>Pincode</label>
            <div className="form-input">
              <input
                type="text"
                className="formField  w-100"
                placeholder="Enter Pincode"
                value={active?.pin_code}
                onChange={(e) => handleInputChange("pin_code", e.target.value)}
              />
            </div>
          </div>
          <div className="form-group mb-2">
            <label>State</label>
            <div className="form-input">
              <input
                type="text"
                className="formField  w-100"
                placeholder="Enter State"
                value={active?.state}
                onChange={(e) => handleInputChange("state", e.target.value)}
              />
            </div>
          </div>
          <div className="form-group mb-2">
            <label>Country</label>
            <div className="form-input">
              <input
                type="text"
                className="formField  w-100"
                placeholder="Enter Country"
                value={active?.country}
                onChange={(e) => handleInputChange("country", e.target.value)}
              />
            </div>
          </div>
          <div className="mb-3 form-check mt-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="termsCheck"
              checked={active?.companyDetail === true}
              onChange={handleCheckboxChangeCompany}
            />
            <label className="form-check-label" htmlFor="termsCheck">
              Add company details (optional)
            </label>
          </div>
          {active?.companyDetail === true && (
            <>
              <div className="form-group mb-2">
                <label>Company Name</label>
                <div className="form-input">
                  <input
                    type="text"
                    className="formField  w-100"
                    placeholder="Enter Company Name"
                    value={active?.companyName}
                    onChange={(e) =>
                      handleInputChange("companyName", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="form-group mb-2">
                <label>GST Number</label>
                <div className="form-input">
                  <input
                    type="text"
                    className="formField  w-100"
                    placeholder="Enter GST  Number"
                    value={active?.gstNo}
                    onChange={(e) => handleInputChange("gstNo", e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div>
          {props?.data?.map((addr, index) => (
            <div key={addr._id} className="address_box">
              <div className="row">
                <div className="col-md-2">
                  <p style={{ color: "#7e7e7e" }} className="font12 med">
                    {`Address ${index + 1}`}
                  </p>
                </div>
                <div className="col-md-8">
                  <p className="text-dark font14 med">
                    {addr?.address_line_1},<br />
                    {addr?.address_line_2}, {addr?.address_line_3}
                    <br />
                    {addr?.state} - {addr?.pin_code} <br />
                    {addr?.companyName} <br />
                    {addr?.gstNo}
                  </p>
                </div>
                <div className="col-md-2">
                  <button
                    className="primary-text font14 sbold"
                    onClick={() => editAddress(addr, index)}
                  >
                    Edit
                  </button>
                  <button
                    className="primary-text font14 sbold ms-3"
                    onClick={() => {
                      handleDelete(addr._id);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
          <button
            className="primary-text font14 sbold address_box"
            onClick={() => openModel("addaddress")}
          >
            Add Address
          </button>
        </div>
      )}
      <Modal show={showModalNew} onHide={handleClose} centered size="md">
        <Modal.Header closeButton className="">
          <Modal.Title>
            <h5 className="mb-0 font20 fontPrimary2 text-dark">
              {modelType === "addaddress" ? <>Add Address</> : null}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modelType === "addaddress" ? (
            <>
              <div className="mb-3">
                <input
                  type="text"
                  className={`formField w-100 ${
                    errors.address_line_1 ? "error" : ""
                  }`}
                  placeholder="Address line 1"
                  name="address_line_1"
                  value={addressFormData.address_line_1}
                  onChange={handleAddressChange}
                />
                {errors.address_line_1 && (
                  <span className="text-danger">
                    Address Line 1 is required
                  </span>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className={`formField w-100 ${
                    errors.address_line_2 ? "error" : ""
                  }`}
                  placeholder="Address line 2"
                  name="address_line_2"
                  value={addressFormData.address_line_2}
                  onChange={handleAddressChange}
                />
                {errors.address_line_2 && (
                  <span className="text-danger">
                    Address Line 2 is required
                  </span>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className={`formField w-100 ${
                    errors.address_line_3 ? "error" : ""
                  }`}
                  placeholder="Address line 3"
                  name="address_line_3"
                  value={addressFormData.address_line_3}
                  onChange={handleAddressChange}
                />
                {errors.address_line_3 && (
                  <span className="text-danger">
                    Address Line 3 is required
                  </span>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className={`formField w-100 ${
                    errors.pin_code ? "error" : ""
                  }`}
                  placeholder="Pincode"
                  name="pin_code"
                  value={addressFormData.pin_code}
                  onChange={handleAddressChange}
                />
                {errors.pin_code && (
                  <span className="text-danger">Pincode is required</span>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className={`formField w-100 ${errors.state ? "error" : ""}`}
                  placeholder="State"
                  name="state"
                  value={addressFormData.state}
                  onChange={handleAddressChange}
                />
                {errors.state && (
                  <span className="text-danger">State is required</span>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className={`formField w-100 ${errors.country ? "error" : ""}`}
                  placeholder="Country"
                  name="country"
                  value={addressFormData.country}
                  onChange={handleAddressChange}
                />
                {errors.country && (
                  <span className="text-danger">Country is required</span>
                )}
              </div>
              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="termsCheck"
                  checked={companyDetail}
                  onChange={handleCheckboxChangeCompany}
                />
                <label className="form-check-label" htmlFor="termsCheck">
                  Add company details (optional)
                </label>
              </div>
              {companyDetail === true && (
                <>
                  <div className="mb-3">
                    <input
                      type="text"
                      className={`formField w-100 ${
                        errors.companyName ? "error" : ""
                      }`}
                      placeholder="Company Name"
                      name="companyName"
                      value={addressFormData.companyName}
                      onChange={handleAddressChange}
                    />
                    {errors.companyName && (
                      <span className="text-danger">
                        Company Name is required
                      </span>
                    )}
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className={`formField w-100 ${
                        errors.gstNo ? "error" : ""
                      }`}
                      placeholder="GST Number"
                      name="gstNo"
                      value={addressFormData.gstNo}
                      onChange={handleAddressChange}
                    />
                    {errors.gstNo && (
                      <span className="text-danger">
                        GST Number is required
                      </span>
                    )}
                  </div>
                </>
              )}

              <div className="mb-3">
                <button
                  className="w-100 common_btn primary btn2 text-center"
                  onClick={handleAddressSubmit}
                >
                  Add
                </button>
              </div>
            </>
          ) : null}
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  );
}
