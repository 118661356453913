import React, { useEffect, useState } from "react";
import images from "../../images";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { deleteFromCart, listCart, updateCart } from "../../API/fakebackend";
import { API_URL } from "../../API/url";
import { formatNumberWithCommas, isLogIn } from "../../../App";
import Login from "../../Auth/Login";
import ForgotPassword from "../../Auth/ForgotPassword";
import Signup from "../../Auth/Signup";
import SetPassword from "../../Auth/SetPassword";

const EmptyCart = () => {
  return (
    <div className="empty_cart">
      <div className="mb-2">
        <img src={images.local_mall} alt="" width={34} />
      </div>
      <p className="primary-text font16 fontPrimary2">You Cart is Empty</p>
    </div>
  );
};

export default function CartSidebar(props) {
  const [data, setData] = useState();
  const [cartData, setCartData] = useState();
  const location = useLocation();

  const fetchData = async () => {
    try {
      const response = await listCart();
      setData(response.data);
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };
  useEffect(() => {
    if (props?.show === true) {
      fetchData();
    }
  }, [props?.show]);

  const updateCartItem = async (values) => {
    try {
      const response = await updateCart(values);
      if (response.result === 1) {
        fetchData();
      }
    } catch (error) {
      console.error("Update Error:", error);
    }
  };

  useEffect(() => {
    if (data) {
      const transformedData =
        data &&
        data?.data.map((item) => ({
          id: item._id,
          name: item.productName,
          category: item.gender,
          price: item.subtotal,
          color: item.color,
          image: `${API_URL}/public${
            item.variation.find((variation) => !variation.is_deleted)?.images[0]
          }`,
          color: item.color,
          quantity: item.quantity,
          customization: item.customization,
          customizationPrice: item.customizationPrice,
          customizations: item.customizations,
          variant: `${item.size_title ? item.size_title : ""}${
            item.size_title
              ? ""
              : item.size_title_top || item.size_title_bottom
              ? `Top: ${item.size_title_top || ""}, Bottom: ${
                  item.size_title_bottom || ""
                }`
              : ""
          }`,
        }));
      setCartData(transformedData);
    }
  }, [data]);

  const del = async (values) => {
    try {
      const response = await deleteFromCart({ itemId: values });
      if (response.result === 1) {
        fetchData();
      }
    } catch (error) {
      console.error("Error While Deleting Item:", error);
    }
  };

  const searchParams = new URLSearchParams(window.location.search);
  let navigate = useNavigate();
  const setParam = (key, value) => {
    searchParams.set(key, value);
    const to = {
      pathname: window.location.pathname,
      search: searchParams.toString(),
    };
    navigate(to, { replace: true });
  };
  const deleteParam = (key) => {
    searchParams.delete(key);
    const to = {
      pathname: window.location.pathname,
      search: searchParams.toString(),
    };
    navigate(to, { replace: true });
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    deleteParam("type");
  };

  const handleClick = (type) => {
    setParam("type", type);
    setTimeout(() => {
      setOpen(true);
    }, 1000);
  };

  useEffect(() => {
    if (isLogIn().isToken && searchParams.get("type")) {
      deleteParam("type");
    }
    if (searchParams.get("type")) {
      setOpen(true);
    }
  }, [location.search]);
  return (
    <div className={`cart_sidebar_outer ${props?.show ? "show" : ""}`}>
      <div className="cart_backdrop" onClick={props?.handleClose}></div>
      <div className="cart_sidebar">
        <div className="login_modal_header">
          <h4 className="font24 bold fontPrimary2 text-dark mb-0">Your cart</h4>
          <button onClick={props?.handleClose}>
            <img src={images.close} alt="" width={14} />
          </button>
        </div>
        {/* <div className="text-end">
          <button className={"btn btn-danger"} style={{ fontSize: "10px" }}>
            Empty Cart
          </button>
        </div> */}
        {cartData?.length ? (
          <div className="cart_block_outer">
            <div className="cart_block">
              {cartData &&
                cartData?.map((opt, i) => {
                  return (
                    <>
                      <div className="cart_item" key={i}>
                        <div className="row gx-3">
                          <div className="col-5">
                            <div className="cart_item_img">
                              <img
                                src={opt.image}
                                alt=""
                                className="img-fluid w-100"
                              />
                            </div>
                          </div>
                          <div className="col-7">
                            <div className="cart_item_info">
                              <h5 className="font16 bold mb-1 fontPrimary2 text-dark d-flex justify-content-between">
                                {opt.name}
                                <span className="ms-auto">
                                  <button
                                    className="primary-text font14 sbold"
                                    onClick={() => {
                                      del(opt.id);
                                    }}
                                  >
                                    Delete
                                  </button>
                                </span>
                              </h5>
                              <p className="font14">{opt.category}</p>
                              <div className="font14 med mt-2">
                                <span
                                  className="color_dot me-2"
                                  style={{ backgroundColor: opt.color }}
                                ></span>
                                {opt.variant}
                              </div>
                              <div className="cart_quantity mt-3">
                                <div className="qty-input">
                                  <button
                                    className="qty-count qty-count--minus"
                                    data-action="minus"
                                    type="button"
                                    onClick={() =>
                                      updateCartItem({
                                        itemId: opt.id,
                                        quantity: -1,
                                      })
                                    }
                                    disabled={opt.quantity === 1}
                                  >
                                    -
                                  </button>
                                  <input
                                    className="product-qty"
                                    type="number"
                                    name="product-qty"
                                    min="0"
                                    max="10"
                                    value={opt.quantity}
                                    readOnly
                                  />
                                  <button
                                    className="qty-count qty-count--add"
                                    data-action="add"
                                    onClick={() =>
                                      updateCartItem({
                                        itemId: opt.id,
                                        quantity: 1,
                                      })
                                    }
                                    type="button"
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="login_modal_header mt-3">
                          <p className="font16">Amount</p>
                          <div className="text-end">
                            <h5 className="font16 bold fontPrimary2 text-dark mb-0">
                              ₹{formatNumberWithCommas(opt.price)}
                            </h5>
                            <p className="font12">Inclusive of all taxes</p>
                          </div>
                        </div>
                        {(opt.customization === true &&
                          opt.customizations?.fontStyle) ||
                        opt.customizations?.fontColor ||
                        opt.customizations?.text ||
                        opt.customizations?.logoUrl ? (
                          <>
                            <div className="mb-1">Customization:</div>
                            <div className="selectedLogoDetails__left d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center">
                                {opt.customizations.logoUrl ? (
                                  <div className="img">
                                    <img
                                      src={`${API_URL}/public${opt.customizations?.logoUrl}`}
                                      alt="Logo Preview"
                                    />
                                  </div>
                                ) : null}
                                <div
                                  className={`content ${
                                    opt.customizations.logoUrl ? "ml-2" : ""
                                  }`}
                                >
                                  <div className="name font16 med text-dark">
                                    {opt.customizations?.text}
                                  </div>
                                  <div className="desc font12 primary-text">
                                    {opt.customizations?.fontColor},
                                    {opt.customizations?.fontStyle}
                                  </div>
                                </div>
                              </div>
                              <div className="text-end">
                                <h5 className="font16 bold fontPrimary2 text-dark mb-0">
                                  ₹
                                  {formatNumberWithCommas(
                                    opt.customizationPrice
                                  )}
                                </h5>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                      <hr />
                    </>
                  );
                })}
            </div>
            <div>
              <div className="login_modal_header">
                <p className="font16 bold text-dark">Sub total Amount</p>
                <br></br>
                <div className="text-end">
                  <h5 className="font16 bold fontPrimary2 text-dark mb-0">
                    ₹{formatNumberWithCommas(data && data.total)}
                  </h5>
                  <p className="font12">
                    Inclusive of all taxes <br></br>
                    {data && data.total_quantity} Items
                  </p>
                </div>
              </div>
              <div className="row gx-2">
                <div className="col">
                  <NavLink
                    to="/cart"
                    className={"common_btn primary2 btn2 w-100"}
                    onClick={props?.handleClose}
                  >
                    Your cart
                  </NavLink>
                </div>
                {isLogIn().isToken ? (
                  <div className="col">
                    <NavLink
                      to="/cart?page=2"
                      className={"common_btn primary btn2 w-100"}
                      onClick={props?.handleClose}
                    >
                      Proceed to Order
                    </NavLink>
                  </div>
                ) : (
                  <div className="col">
                    <button
                      className={"common_btn primary btn2 w-100"}
                      onClick={() => {
                        props?.handleClose();
                        handleClick("login");
                      }}
                    >
                      Proceed to Order
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <EmptyCart />
        )}

        {/* {!isLogIn().isToken && searchParams.get("type") ? (
          <>
            {searchParams.get("type") == "set-password" ? (
              <SetPassword show={open} handleClick={handleClick} />
            ) : searchParams.get("type") == "signup" ? (
              <Signup
                show={open}
                handleClick={handleClick}
                handleClose={handleClose}
              />
            ) : searchParams.get("type") == "forgot" ? (
              <ForgotPassword show={open} handleClick={handleClick} />
            ) : (
              <Login
                show={open}
                handleClose={handleClose}
                handleClick={handleClick}
              />
            )}
          </>
        ) : null} */}
      </div>
    </div>
  );
}
