import React, { useState } from "react";
import images from "../images";
import { forgotPassword } from "../API/fakebackend";

export default function ForgotPassword(props) {
  const [showForm, setShowForm] = useState(true);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (emailError) {
      setEmailError("");
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
    if (otpError) {
      setOtpError("");
    }
  };

  const handleSubmitEmail = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }

    try {
      const response = await forgotPassword({ email: email });
      if (response.result === 1) {
        setShowForm(false);
      }
    } catch (error) {
      console.error("Error submitting email:", error);
    }
  };

  const handleSubmitOtp = async () => {
    try {
      const response = await forgotPassword({ email: email, otp: otp });
      if (response.result === 1) {
        props.handleData(otp, email);
        props.handleClick("set-password");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      if (error.response && error.response.data && error.response.data.msg) {
        setOtpError(error.response.data.msg);
      } else {
        setOtpError("Failed to verify OTP. Please try again.");
      }
    }
  };

  return (
    <div className={`login_modal ${props.show ? "show" : ""}`}>
      <div className="login_modal_container">
        <div className="login_modal_header">
          <img src={images.logo} alt="" width={200} />
        </div>
        {showForm ? (
          <div className="login_modal_form">
            <div className="my-5">
              <button
                style={{ color: "#A3A3A3" }}
                className="med font14"
                onClick={() => props.handleClick("login")}
              >
                <img src={images.arrow_back} alt="" className="me-3" />
                Back to Login
              </button>
            </div>
            <h4 className="font20 text-dark bold fontPrimary2 mb-4">
              Forgot password?
            </h4>
            <div>
              <div className="mb-2">
                <input
                  type="email"
                  className="formField w-100"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={handleEmailChange}
                />
                {emailError && <p className="text-danger">{emailError}</p>}
              </div>
              <div className="login_modal_header mt-4 align-items-center">
                <button
                  className="common_btn primary btn2"
                  onClick={handleSubmitEmail}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="login_modal_form">
            <div className="my-5">
              <button
                style={{ color: "#A3A3A3" }}
                className="med font14"
                onClick={() => setShowForm(true)}
              >
                <img src={images.arrow_back} alt="" className="me-3" />
                Back
              </button>
            </div>
            <h4 className="font20 text-dark bold fontPrimary2 mb-4">
              Enter OTP
            </h4>
            <div>
              <p className="font14 mb-4" style={{ color: "#7e7e7e" }}>
                We have sent you an OTP to your Email address {email}
              </p>
              <div className="mb-2">
                <input
                  type="text"
                  className="formField w-100"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={handleOtpChange}
                />
                {otpError && <p className="text-danger">{otpError}</p>}
              </div>
              <div className="login_modal_header mt-4 align-items-center">
                <button
                  className="common_btn primary btn2"
                  onClick={handleSubmitOtp}
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
