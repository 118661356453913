import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import images from "../../images";
import "./ProductDetail.css";
import { Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper/modules";
import Product from "../../common/Product";
import { Link, useNavigate, useParams } from "react-router-dom";
import RviewBox from "../../common/RviewBox/RviewBox";
import { formatNumberWithCommas, isLogIn } from "../../../App";
import { ToastContainer } from "react-toastify";
import {
  productDetail,
  customizationColors,
  addToCart,
  uploadCustomizationImage,
} from "../../API/fakebackend";
import Rating from "react-rating";
import { API_URL } from "../../API/url";
import { toast } from "react-toastify";
// import SetPassword from "../../Auth/SetPassword";
// import Signup from "../../Auth/Signup";
// import Login from "../../Auth/Login";
// import ForgotPassword from "../../Auth/ForgotPassword";
import ReactImageMagnify from "react-image-magnify";

const color = [
  { name: "Galaxy Blue", code: "#1E85D3", id: "galaxy_blue" },
  { name: "Maroon", code: "#941826", id: "maroon" },
  { name: "Olive", code: "#32AD9F", id: "olive" },
  { name: "Hot Pink", code: "#E67988", id: "hot_pink" },
  { name: "Navy", code: "#243458", id: "navy" },
];
const tshirtColor = [
  { name: "White", code: "#fff", id: "white" },
  { name: "Maroon", code: "#941826", id: "maroon2" },
  { name: "Mustard", code: "#F88D2C", id: "mustard" },
  { name: "Hot Pink", code: "#E67988", id: "hot_pink" },
];

const customTab = [
  { name: "Name", id: "name", value: "TEXT" },
  { name: "Logo", id: "logo", value: "LOGO" },
  { name: "Both", id: "both", value: "BOTH" },
];
const fontStyle = [
  { name: "Block", id: "block" },
  { name: "Script", id: "script" },
];
export const bestseller = [
  {
    name: "Classic Flexi Scrub ",
    category: "Womens",
    price: "₹ 1,500.00",
    image: images.img1,
    rating: "4.8",
    hot: true,
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Lab coats",
    price: "₹ 1,500.00",
    image: images.img2,
    rating: "4.8",
    new: true,
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Womens",
    price: "₹ 1,500.00",
    image: images.img3,
    rating: "4.8",
    new: true,
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Womens",
    price: "₹ 1,500.00",
    image: images.img4,
    rating: "4.8",
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Mens",
    price: "₹ 1,500.00",
    image: images.img5,
    rating: "4.8",
    hot: true,
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Womens",
    price: "₹ 1,500.00",
    image: images.img6,
    rating: "4.8",
    new: true,
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Womens",
    price: "₹ 1,500.00",
    image: images.img7,
    rating: "4.8",
    new: true,
  },
];

export default function ProductDetail() {
  const [productData, setProductData] = useState([]);
  const [authUser, setAuthUser] = useState("");
  const { id } = useParams();
  const [relevantProducts, setRelevantProducts] = useState("");
  const [price, setPrice] = useState("");
  const [variation, setVariation] = useState("");
  const [productImages, setProductImages] = useState("");
  const [custColors, setCustColors] = useState();
  const [count, setcount] = useState(1);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [customization, setCustomization] = useState({
    customizeType: "TEXT",
    fontStyle: "",
    fontColor: "",
    text: "",
    logoUrl: "",
  });

  useEffect(() => {
    setAuthUser(JSON.parse(localStorage.getItem("authUser")));
  }, []);

  const validateCustomiztion = () => {
    const errors = {};

    if (
      customization.customizeType === "TEXT" ||
      customization.customizeType === "BOTH"
    ) {
      if (!customization.text) {
        errors.text = "Full Name is required!";
      }
      if (!customization.fontColor) {
        errors.fontColor = "Font color is required!";
      }
      if (!customization.fontStyle) {
        errors.fontStyle = "Font style is required!";
      }
    }

    if (
      customization.customizeType === "LOGO" ||
      customization.customizeType === "BOTH"
    ) {
      if (!customization.logoUrl) {
        errors.logoUrl = "Logo is required!";
      }
    }

    return errors;
  };

  const handleCustomizationSubmit = () => {
    const errors = validateCustomiztion();
    if (Object.keys(errors).length === 0) {
      setFormData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          customizations: customization,
        };
        handleClose();
        return updatedFormData;
      });
    } else {
      setErrors(errors);
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      product_id: (productData && productData?.detail?._id) || "",
      quantity: count || 1,
      gender: (productData && productData?.detail?.gender) || "",
      categoryId: (productData && productData?.detail?.category_id) || "",
      subcategoryId: (productData && productData?.detail?.subcategory_id) || "",
      productName: (productData && productData?.detail?.product_name) || "",
      multiSize: (productData && productData?.detail?.multiSize) || false,
      customization:
        (productData && productData?.detail?.customization) || false,
      // price: (productData && productData?.detail?.regular_price) || 0,

      user_id: authUser?.data?.user?._id || null,
      variationId: (variation && variation[0]?._id) || "",
    });
  }, [productData, authUser, count, variation]);

  useEffect(() => {
    if (productData && productData?.detail?.multiSize === false) {
      setFormData({
        ...formData,
        price: (productData && productData?.detail?.regular_price) || 0,
      });
    }
  }, [productData, authUser]);

  const validate = () => {
    const errors = {};

    const sizePrice = productData?.detail?.size_price;

    const isSizePriceEmpty = !sizePrice || sizePrice.length === 0;

    if (productData?.detail?.multiSize) {
      if (!formData?.sizeId_top) {
        errors.sizeId_top = "Top Size is required!";
      }
      if (!formData?.sizeId_bottom) {
        errors.sizeId_bottom = "Bottom Size is required!";
      }
    } else {
      if (!isSizePriceEmpty && !formData?.sizeId) {
        errors.sizeId = "Size is required!";
      }
    }

    return errors;
  };

  const [formData, setFormData] = useState({
    product_id: "",
    sizeId_top: null,
    sizeId: null,
    sizeId_bottom: null,
    size_title: "",
    size_title_top: "",
    size_title_bottom: "",
    variationId: "",
    quantity: 1,
    color: (variation && variation[0]?.color_hexacode) || "",
    price: "",
    gender: "",
    categoryId: "",
    subcategoryId: "",
    productName: "",
    multiSize: "",
    price_top: "",
    price_bottom: "",
    user_id: null,
    customization: "",
    customizations: {
      customizeType: "",
      fontStyle: "",
      fontColor: "",
      text: "",
      logoUrl: "",
    },
  });

  const add = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      const value = formData?.customizations?.logoUrl;
      let updatedFormData = formData;

      if (value) {
        try {
          const image = await uploadCustomizationImage({ file: value });
          updatedFormData = {
            ...formData,
            customizations: {
              ...formData.customizations,
              logoUrl: image.data[0],
            },
          };
          setFormData(updatedFormData);
        } catch (error) {
          toast.error(
            "ERROR",
            error.response?.data?.msg || "Image upload failed",
            { autoClose: 2000 }
          );
          throw error;
        }
      }

      const response = await addToCart(updatedFormData);
      if (response.result === 1) {
        toast.success("Item Added to Cart", { autoClose: 2000 });
        setErrors("");
      } else {
        toast.error("ERROR", response.data.msg, { autoClose: 2000 });
      }
    } catch (error) {
      console.error("An Error Occured:", error);
      toast.error(error.response?.data?.msg || "An unexpected error occurred", {
        autoClose: 2000,
      });
    }
  };
  const buyNow = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      const value = formData?.customizations?.logoUrl;
      let updatedFormData = formData;

      if (value) {
        try {
          const image = await uploadCustomizationImage({ file: value });
          updatedFormData = {
            ...formData,
            customizations: {
              ...formData.customizations,
              logoUrl: image.data[0],
            },
          };
          setFormData(updatedFormData);
        } catch (error) {
          toast.error(
            "ERROR",
            error.response?.data?.msg || "Image upload failed",
            { autoClose: 2000 }
          );
          throw error;
        }
      }

      const response = await addToCart(updatedFormData);
      if (response.result === 1) {
        navigate("/cart?page=2");
      } else {
        toast.error("ERROR", response.data.msg, { autoClose: 2000 });
      }
    } catch (error) {
      console.error("An Error Occured:", error);
      toast.error(error.response?.data?.msg || "An unexpected error occurred", {
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    if (formData.sizeId) {
      const filteredItem = productData?.detail?.size_price?.find(
        (item) => item.size_id === formData.sizeId
      );

      if (filteredItem) {
        setPrice(filteredItem.price);
        setFormData({
          ...formData,
          price: filteredItem.price,
        });
      } else {
        setPrice("");
      }
    }
  }, [formData.sizeId, productData]);

  useEffect(() => {
    if (formData.sizeId_top || formData.sizeId_bottom) {
      const filteredItemTop = productData?.detail?.size_price_top?.find(
        (item) => item.size_id === formData.sizeId_top
      );

      const filteredItemBottom = productData?.detail?.size_price_bottom?.find(
        (item) => item.size_id === formData.sizeId_bottom
      );

      const filteredItem =
        Number(filteredItemTop?.price || 0) +
        Number(filteredItemBottom?.price || 0);

      if (filteredItem) {
        setPrice(filteredItem);
        setFormData({
          ...formData,
          price_top: Number(filteredItemTop?.price || 0),
          price_bottom: Number(filteredItemBottom?.price || 0),
        });
      } else {
        setPrice("");
      }
    }
  }, [formData.sizeId_top, formData.sizeId_bottom, productData]);

  useEffect(() => {
    if (formData.color) {
      const filteredItem = variation.find(
        (item) => item.color_hexacode === formData.color
      );

      if (filteredItem) {
        setProductImages(filteredItem.images);
      } else {
        setProductImages([]);
      }
    }
  }, [formData.color, variation]);

  useEffect(() => {
    setPrice(productData && productData?.detail?.regular_price);
  }, [productData]);

  useEffect(() => {
    setVariation(productData && productData?.detail?.variation);
  }, [productData]);

  useEffect(() => {
    if (variation && variation.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        color: variation[0].color_hexacode,
      }));
    }
  }, [productData, variation]);

  const fetchColor = async () => {
    try {
      const data = await customizationColors();
      setCustColors(data.data);
    } catch (error) {
      console.error("Error fetching customization colors:", error);
    }
  };

  useEffect(() => {
    fetchColor();
  }, []);

  const handleChange = (e) => {
    setCustomization({
      ...customization,
      logoUrl: e.target.files[0],
    });
  };

  const handleDelete = () => {
    setCustomization({
      ...customization,
      logoUrl: "",
    });
  };

  const handleDeleteView = () => {
    setFormData({
      ...formData,
      customizations: {
        customizeType: "",
        fontStyle: "",
        fontColor: "",
        text: "",
        logoUrl: "",
      },
    });
  };

  useEffect(() => {
    if (productData && productData.relevantProducts?.[0]?.products) {
      const transformedData = productData.relevantProducts[0].products.map(
        (item) => ({
          slug: item.slug,
          name: item.product_name,
          category: item.category_name,
          price: item.regular_price,
          rating: item.overallRating?.rating,
          image: `${API_URL}/public${
            item.variation.find((variation) => !variation.is_deleted)?.images[0]
          }`,
          color: item.variation
            .filter((variation) => !variation.is_deleted)
            .map((variation) => variation.color_hexacode),
        })
      );
      setRelevantProducts(transformedData);
    }
  }, [productData]);

  const fetchData = async () => {
    try {
      const data = await productDetail(`${id}?relevantProducts=true`);
      setProductData(data.data);
    } catch (error) {
      console.error("Error fetching product detail data:", error);
    }
  };

  useEffect(() => {
    if (authUser) {
      fetchData();
    }
  }, [authUser, id]);

  const [showModalNew, setshowModalNew] = useState(false);
  const [modelType, setmodelType] = useState("");
  const handleClose = () => {
    setshowModalNew(false);
  };
  const openModel = (type) => {
    setmodelType(type);
    setshowModalNew(true);
  };
  const updatecount = (type) => {
    if (type === "add") {
      setcount((prevCount) => prevCount + 1);
    } else {
      setcount((prevCount) => (prevCount > 1 ? prevCount - 1 : 1));
    }
  };

  const updateCountInput = (e) => {
    const value = parseInt(e.target.value, 10);
    if (isNaN(value) || value < 1) {
      setcount(1);
    } else {
      setcount(value);
    }
  };
  const mainSliderRef = useRef(null);
  const thumbnailSliderRef = useRef(null);
  const slideImg = [images.slide1, images.slide2, images.slide3, images.slide4];
  useEffect(() => {
    setNav1(mainSliderRef.current);
    setNav2(thumbnailSliderRef.current);
  }, []);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const mainSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: nav2,
    loop: false,
    infinite: false,
  };

  const thumbSettings = {
    vertical: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    verticalSwiping: false,
    asNavFor: nav1,
    arrows: false,
    loop: false,
    infinite: false,
  };
  const [tab, settab] = useState("name");
  const changeTab = (name, customizeType) => {
    settab(name);
  };

  const clearCustomizationState = () => {
    if (tab === "name") {
      setCustomization({
        customizeType: "TEXT",
        fontStyle: "",
        fontColor: "",
        text: "",
        logoUrl: null,
      });
    } else if (tab === "logo") {
      setCustomization({
        customizeType: "LOGO",
        fontStyle: "",
        fontColor: "",
        text: "",
        logoUrl: null,
      });
    } else if (tab === "both") {
      setCustomization({
        customizeType: "BOTH",
        fontStyle: "",
        fontColor: "",
        text: "",
        logoUrl: null,
      });
    }
  };

  useEffect(() => {
    clearCustomizationState();
  }, [tab]);

  const searchParams = new URLSearchParams(window.location.search);
  const setParam = (key, value) => {
    searchParams.set(key, value);
    const to = {
      pathname: window.location.pathname,
      search: searchParams.toString(),
    };
    navigate(to, { replace: true });
  };
  const deleteParam = (key) => {
    searchParams.delete(key);
    const to = {
      pathname: window.location.pathname,
      search: searchParams.toString(),
    };
    navigate(to, { replace: true });
  };
  const [showSearch, setshowSearch] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [cartOpen, setcartOpen] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const handleCloseNew = () => {
    setshowModal(false);
    setOpen(false);
    setcartOpen(false);
    deleteParam("type");
  };

  const handleClick = (type) => {
    setParam("type", type);
    setTimeout(() => {
      setOpen(true);
    }, 1000);
  };

  return (
    <>
      <section className="prodctDtlSctin">
        <div className="container">
          <div className="prodctDtlSctin__row row">
            <div className="col-md-7">
              <div className="prodctDtlSctin__left">
                <div className="productSliderRow d-flex">
                  <div className="productSliderRow__left">
                    <Slider {...thumbSettings} ref={thumbnailSliderRef}>
                      {productImages &&
                        productImages.map((img, i) => (
                          <div key={i}>
                            <img
                              src={`${API_URL}/public${img}`}
                              alt={`thumbnail-${i}`}
                            />
                          </div>
                        ))}
                    </Slider>
                  </div>
                  <div className="productSliderRow__right ">
                    <Slider {...mainSettings} ref={mainSliderRef}>
                      {productImages &&
                        productImages.map((img, k) => (
                          <div key={k}>
                            <ReactImageMagnify
                              {...{
                                smallImage: {
                                  alt: `IMG`,
                                  isFluidWidth: true,
                                  src: `${API_URL}/public${img}`,
                                },
                                largeImage: {
                                  src: `${API_URL}/public${img}`,
                                  width: 1200,
                                  height: 1800,
                                },
                                enlargedImageContainerClassName:
                                  "enlargedImageContainer",
                                enlargedImagePosition: "over",
                                zoomFactor: 2,
                              }}
                            />
                          </div>
                        ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="prodctDtlSctin__right">
                <ul className="breadcrums">
                  <li>
                    <Link>
                      {productData && productData?.detail?.category_name}
                    </Link>
                  </li>
                  <li>
                    {productData && productData?.detail?.subcategory_name}
                  </li>
                </ul>

                <div className=" ">
                  <div className="d-flex justify-content-between">
                    <h5 className="bold fontPrimary2 text-dark m-0">
                      {productData && productData?.detail?.product_name}
                    </h5>
                    {/* <button className="shareButton">
                      <img src={images.share} />
                    </button> */}
                  </div>
                  <div className="">
                    {productData && productData?.detail?.gender !== "BOTH"
                      ? productData && productData?.detail?.gender
                      : null}
                  </div>
                  <div className="">
                    {productData && productData?.detail?.pockets
                      ? `${productData && productData?.detail?.pockets} Pockets`
                      : null}
                  </div>
                </div>
                <div className=" ">
                  <div className="mb-2">Price</div>
                  <div>
                    <h5 className="bold fontPrimary2 text-dark ">
                      ₹{formatNumberWithCommas(price && price)}
                    </h5>
                    <div className="font12">Inclusive of all taxes</div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center ">
                  <div className="d-flex ">
                    <div className="d-inline-flex ratingStar">
                      {productData?.detail?.overallRating?.rating ? (
                        <Rating
                          initialRating={
                            productData &&
                            productData?.detail?.overallRating?.rating
                          }
                          fullSymbol={
                            <img
                              src={images.star}
                              className="icon"
                              alt="filled star"
                            />
                          }
                          emptySymbol={
                            <div
                              style={{ width: "20px", height: "20px" }}
                            ></div>
                          }
                          readonly
                        />
                      ) : (
                        <span>No rating found</span>
                      )}
                    </div>
                    <div className="font14 med ms-1">
                      {productData &&
                        productData?.detail?.overallRating?.rating}{" "}
                      {productData?.detail?.overallRating?.rating
                        ? "rating"
                        : null}
                    </div>
                  </div>
                  <div>
                    <button className="reviewsbutton">
                      <span>Reviews</span>
                      <img
                        className="ms-1"
                        width="10"
                        alt=""
                        src={images.expand_more}
                      />
                    </button>
                  </div>
                </div>
                <hr className="m-0" />
                <div>
                  <div className="produtPagTitle font14 med mb-2">Color</div>
                  <div className="selectionHolder d-flex">
                    {variation &&
                      variation.map((data, i) => {
                        return (
                          <div key={i} className="selectionHolder__box">
                            <input
                              type="radio"
                              id={data._id}
                              name="color"
                              value={data.color_hexacode}
                              checked={formData.color === data.color_hexacode}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  color: e.target.value,
                                  variationId: data._id,
                                });
                              }}
                            />
                            <label htmlFor={data._id}>
                              <span
                                className="color"
                                style={{ background: data.color_hexacode }}
                              ></span>
                              <span className="name">{data.color_name}</span>
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>

                {productData?.detail?.multiSize === false ? (
                  <>
                    {productData?.detail?.size_price?.length > 0 && (
                      <div>
                        <div className="mb-2 d-flex justify-content-between align-items-center">
                          <div className="produtPagTitle font14 med d-flex">
                            Size{" "}
                            {errors.sizeId && (
                              <p
                                className="text-danger"
                                style={{ marginLeft: "8px" }}
                              >
                                {errors.sizeId}
                              </p>
                            )}
                          </div>
                          <button
                            onClick={() => openModel("sizeChart")}
                            className="produtPagTitle primary-text font14 med"
                          >
                            {productData &&
                            productData?.detail?.sizechart &&
                            productData?.detail?.sizechart?.length > 0
                              ? "Size Chart"
                              : null}
                          </button>
                        </div>
                        <div className="selectionHolder d-flex">
                          {productData &&
                            productData?.detail?.size_price?.map(
                              (data, ind) => {
                                return (
                                  <div
                                    key={ind}
                                    className="selectionHolder__box"
                                  >
                                    <input
                                      type="radio"
                                      id={data.size_id}
                                      name="sizeId"
                                      value={data.size_id}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          sizeId: e.target.value,
                                          size_title: data.size_title,
                                        });
                                      }}
                                    />
                                    <label htmlFor={data.size_id}>
                                      {data.size_title}
                                    </label>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {productData?.detail?.size_price_top?.length > 0 && (
                      <div>
                        <div className="mb-2 d-flex justify-content-between align-items-center">
                          <div className="produtPagTitle font14 med d-flex">
                            Top Size{" "}
                            {errors.sizeId_top && (
                              <p
                                className="text-danger"
                                style={{ marginLeft: "8px" }}
                              >
                                {errors.sizeId_top}
                              </p>
                            )}
                          </div>
                          <button
                            onClick={() => openModel("topChart")}
                            className="produtPagTitle primary-text font14 med"
                          >
                            {productData &&
                            productData?.detail?.sizecharttop &&
                            productData?.detail?.sizecharttop?.length > 0
                              ? "Size Chart"
                              : null}
                          </button>
                        </div>
                        <div className="selectionHolder d-flex">
                          {productData &&
                            productData?.detail?.size_price_top?.map(
                              (data, ind) => (
                                <div key={ind} className="selectionHolder__box">
                                  <input
                                    type="radio"
                                    id={`top_${data.size_id}`}
                                    name="sizeId_top"
                                    value={data.size_id}
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        sizeId_top: e.target.value,
                                        size_title_top: data.size_title,
                                      });
                                    }}
                                  />
                                  <label htmlFor={`top_${data.size_id}`}>
                                    {data.size_title}
                                  </label>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    )}

                    {productData?.detail?.size_price_bottom?.length > 0 && (
                      <div>
                        <div className="mb-2 d-flex justify-content-between align-items-center">
                          <div className="produtPagTitle font14 med d-flex">
                            Bottom Size{" "}
                            {errors.sizeId_bottom && (
                              <p
                                className="text-danger"
                                style={{ marginLeft: "8px" }}
                              >
                                {errors.sizeId_bottom}
                              </p>
                            )}
                          </div>
                          <button
                            onClick={() => openModel("bottomChart")}
                            className="produtPagTitle primary-text font14 med"
                          >
                            {productData &&
                            productData?.detail?.sizechartbottom &&
                            productData?.detail?.sizechartbottom?.length > 0
                              ? "Size Chart"
                              : null}
                          </button>
                        </div>
                        <div className="selectionHolder d-flex">
                          {productData &&
                            productData?.detail?.size_price_bottom?.map(
                              (data, ind) => (
                                <div key={ind} className="selectionHolder__box">
                                  <input
                                    type="radio"
                                    id={`bottom_${data.size_id}`}
                                    name="sizeId_bottom"
                                    value={data.size_id}
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        sizeId_bottom: e.target.value,
                                        size_title_bottom: data.size_title,
                                      });
                                    }}
                                  />
                                  <label htmlFor={`bottom_${data.size_id}`}>
                                    {data.size_title}
                                  </label>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div>
                  <div className="mb-2 produtPagTitle font14 med">Quantity</div>

                  <div className="qty-input">
                    <button
                      className="qty-count qty-count--minus"
                      data-action="minus"
                      type="button"
                      onClick={() => updatecount("minus")}
                      disabled={count === 1}
                    >
                      -
                    </button>
                    <input
                      className="product-qty"
                      type="number"
                      name="product-qty"
                      min="0"
                      max="10"
                      value={count}
                      onChange={(e) => updateCountInput(e)}
                    />
                    <button
                      className="qty-count qty-count--add"
                      data-action="add"
                      onClick={() => updatecount("add")}
                      type="button"
                    >
                      +
                    </button>
                  </div>
                </div>
                {((formData && formData?.customizations?.fontStyle) ||
                  (formData && formData?.customizations?.fontColor) ||
                  (formData && formData?.customizations?.text) ||
                  (formData && formData?.customizations?.logoUrl)) && (
                  <div className="selectedLogoDetails d-flex justify-content-between align-items-center">
                    <div className="selectedLogoDetails__left d-flex align-items-center">
                      <div className="img">
                        {customization.logoUrl ? (
                          <img
                            src={
                              formData?.customizations?.logoUrl instanceof File
                                ? URL.createObjectURL(
                                    formData.customizations.logoUrl
                                  )
                                : `${API_URL}/public${formData?.customizations?.logoUrl}`
                            }
                            alt="Logo Preview"
                          />
                        ) : null}
                      </div>
                      <div className="content">
                        <div className="name font16 med text-dark">
                          {formData && formData?.customizations?.text}
                        </div>
                        <div className="desc font12 primary-text">
                          {formData && formData?.customizations?.fontColor},
                          {formData && formData?.customizations?.fontStyle}
                        </div>
                      </div>
                    </div>
                    <ul className="selectedLogoDetails__right d-flex">
                      {/* <li>
                        <button>Edit</button>
                      </li> */}
                      <li>
                        <button onClick={handleDeleteView}>Delete</button>
                      </li>
                    </ul>
                  </div>
                )}
                <div className="buttonHolder d-flex">
                  {isLogIn().isToken ? (
                    <button
                      className="buttonHolder__button buttonHolder__blue"
                      onClick={buyNow}
                    >
                      Buy Now
                    </button>
                  ) : (
                    <button
                      className="buttonHolder__button buttonHolder__blue"
                      onClick={() => handleClick("login")}
                    >
                      Buy Now
                    </button>
                  )}
                  <button
                    className="buttonHolder__button buttonHolder__grey"
                    onClick={add}
                  >
                    <svg
                      width="17"
                      height="20"
                      viewBox="0 0 17 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.8077 19.4999C1.30898 19.4999 0.883025 19.3233 0.529825 18.9701C0.176608 18.6169 0 18.1909 0 17.6922V6.30765C0 5.80893 0.176608 5.38297 0.529825 5.02977C0.883025 4.67656 1.30898 4.49995 1.8077 4.49995H4C4 3.25125 4.43782 2.18908 5.31345 1.31345C6.1891 0.437817 7.25128 0 8.49998 0C9.74868 0 10.8109 0.437817 11.6865 1.31345C12.5621 2.18908 13 3.25125 13 4.49995H15.1923C15.691 4.49995 16.1169 4.67656 16.4701 5.02977C16.8233 5.38297 17 5.80893 17 6.30765V17.6922C17 18.1909 16.8233 18.6169 16.4701 18.9701C16.1169 19.3233 15.691 19.4999 15.1923 19.4999H1.8077ZM1.8077 17.9999H15.1923C15.2692 17.9999 15.3397 17.9679 15.4038 17.9038C15.4679 17.8397 15.5 17.7691 15.5 17.6922V6.30765C15.5 6.23072 15.4679 6.16019 15.4038 6.09608C15.3397 6.03198 15.2692 5.99993 15.1923 5.99993H1.8077C1.73077 5.99993 1.66024 6.03198 1.59612 6.09608C1.53202 6.16019 1.49997 6.23072 1.49997 6.30765V17.6922C1.49997 17.7691 1.53202 17.8397 1.59612 17.9038C1.66024 17.9679 1.73077 17.9999 1.8077 17.9999ZM5.49998 4.49995H11.5C11.5 3.66662 11.2083 2.95828 10.625 2.37495C10.0416 1.79162 9.33331 1.49995 8.49998 1.49995C7.66664 1.49995 6.95831 1.79162 6.37498 2.37495C5.79164 2.95828 5.49998 3.66662 5.49998 4.49995ZM8.4846 11.4999C9.61535 11.4999 10.631 11.1172 11.5317 10.3518C12.4323 9.58644 12.8775 8.71785 12.8672 7.74605C12.8672 7.53965 12.798 7.3637 12.6596 7.2182C12.5211 7.07268 12.3455 6.99993 12.1327 6.99993C11.957 6.99993 11.8 7.0605 11.6615 7.18165C11.5231 7.3028 11.425 7.47171 11.3673 7.68838C11.2224 8.36658 10.8769 8.92138 10.3308 9.3528C9.7846 9.78422 9.16922 9.99993 8.4846 9.99993C7.79997 9.99993 7.18202 9.78422 6.63075 9.3528C6.07947 8.92138 5.73652 8.36658 5.6019 7.68838C5.5442 7.46146 5.45125 7.28998 5.32305 7.17395C5.19485 7.05793 5.04293 6.99993 4.8673 6.99993C4.65448 6.99993 4.47628 7.07268 4.3327 7.2182C4.18912 7.3637 4.11733 7.53965 4.11733 7.74605C4.11733 8.71785 4.56251 9.58644 5.45288 10.3518C6.34326 11.1172 7.35383 11.4999 8.4846 11.4999Z"
                        fill="#082A6B"
                      />
                    </svg>
                    Add To Cart
                  </button>
                </div>
                {productData?.detail?.customization === true && (
                  <div className="customEmbrodry d-flex align-items-center">
                    <div className="customEmbrodry__left">
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.07708 11.8618L12.1284 8.81048L9.35908 6.04115L7.75908 7.65415L6.69242 6.58748L8.30542 4.98748L5.52308 2.22582L2.47208 5.27715L9.07708 11.8618ZM20.2001 23.0258L23.2514 19.9541L20.4694 17.1718L18.8694 18.7848L17.8028 17.7181L19.4028 16.1181L16.6798 13.3821L13.6284 16.4335L20.2001 23.0258ZM6.27442 23.8335H1.67708V19.2361L7.99008 12.9231L0.34375 5.27715L5.48975 0.0771484L13.2028 7.75648L19.3541 1.60548C19.5112 1.44815 19.6821 1.3357 19.8668 1.26815C20.0514 1.20059 20.2403 1.16682 20.4334 1.16682C20.6181 1.16682 20.8028 1.20059 20.9874 1.26815C21.1719 1.3357 21.3428 1.44815 21.5001 1.60548L23.9051 4.04882C24.0624 4.20615 24.1728 4.37715 24.2361 4.56181C24.2992 4.74626 24.3308 4.93082 24.3308 5.11548C24.3308 5.30859 24.2992 5.49537 24.2361 5.67582C24.1728 5.85604 24.0624 6.02482 23.9051 6.18215L17.7798 12.3078L25.4334 19.9875L20.2668 25.1461L12.6078 17.5001L6.27442 23.8335ZM3.19008 22.3205H5.61308L18.7258 9.18715L16.3028 6.76415L3.19008 19.8975V22.3205Z"
                          fill="#F88D2C"
                        />
                      </svg>
                    </div>
                    <div className="customEmbrodry__center">
                      <div className="font16 fontPrimary2  primary-text med">
                        Get custom Embroidery
                      </div>
                      <div className="font14 fontPrimary2  primary-text">
                        {" "}
                        Add your logo/ name/ icon
                      </div>
                    </div>
                    <div className="customEmbrodry__right">
                      <button
                        onClick={() => openModel("customize")}
                        className="customEmbrodry__button"
                      >
                        Customize
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common_sec prodctDtlSctin">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="productDetailboxes">
                <div className="productDetailboxes__img">
                  <img src={images.productDetail} />
                </div>
                <div className="productDetailboxes__cntnt">
                  <h5 className="productDetailboxes__title fontPrimary2 bold ">
                    Product detail
                  </h5>
                  <p>{productData && productData?.detail?.product_detail}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="productDetailboxes">
                <div className="productDetailboxes__img">
                  <img src={images.matetrialMaintain} />
                </div>
                <div className="productDetailboxes__cntnt">
                  <h5 className="productDetailboxes__title fontPrimary2 bold">
                    Material and maintain
                  </h5>
                  <ul>
                    {productData && productData?.detail?.material_and_maintain}
                  </ul>

                  {/* <div className="productDetailboxes__innerList d-flex flex-wrap">
                    <img src={images.iron} />
                    <img src={images.laundry} />
                    <img src={images.panorama_horizontalOutline} />
                    65% Poly, 25% viscose
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="productDetailboxes">
                <div className="productDetailboxes__img">
                  <img src={images.returnpolicy} />
                </div>
                <div className="productDetailboxes__cntnt">
                  <h5 className="productDetailboxes__title fontPrimary2 bold primary-text">
                    Return policy
                  </h5>
                  <p>{productData && productData?.detail?.return_policy}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common_sec reviewBox">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div className="heading_block">
                <h3 className="mb-0 fontPrimary2 bold">
                  {productData && productData?.detail?.overallRating?.rating}
                </h3>
                <p className="font14 med fontPrimary2 text-light2">
                  {productData &&
                    productData?.detail?.overallRating?.noOfRating}{" "}
                  Reviews
                </p>
                {productData?.detail?.overallRating?.rating ? (
                  <Rating
                    initialRating={
                      productData && productData?.detail?.overallRating?.rating
                    }
                    fullSymbol={
                      <img
                        src={images.star}
                        className="icon"
                        alt="filled star"
                      />
                    }
                    emptySymbol={
                      <div style={{ width: "20px", height: "20px" }}></div>
                    }
                    readonly
                  />
                ) : (
                  <span>No Reviews Found</span>
                )}
              </div>
            </div>
            <div className="col-md-10">
              <h5 className="mb-0 fontPrimary2 ">Reviews</h5>
              {productData &&
                productData?.detail?.reviews.map((data, index) => {
                  return (
                    <div className="reviewBox" key={index}>
                      {data?.images?.length > 0 ? (
                        <div className="reviewBox__images">
                          {data?.images?.map((im, i) => {
                            return (
                              <img
                                key={i}
                                src={`${API_URL}/public${im}`}
                                alt="Alt"
                              />
                            );
                          })}
                        </div>
                      ) : null}
                      <div className="reviewBox__content">
                        <div className="reviewBox__user d-flex align-items-center">
                          <div className="reviewBox__userContent">
                            <div className="text-dark med font14 fontPrimary2">
                              {data.user_full_name}
                            </div>
                            <div className="text-dark med font14 fontPrimary2">
                              {data.createdAt}
                            </div>
                          </div>
                        </div>
                        <p>{data.review}</p>
                        <div className="d-flex reviewBox__rating">
                          <div className="d-inline-flex ratingStar">
                            <Rating
                              initialRating={data?.rating}
                              fullSymbol={
                                <img
                                  src={images.star}
                                  className="icon"
                                  alt="filled star"
                                />
                              }
                              emptySymbol={
                                <div
                                  style={{ width: "20px", height: "20px" }}
                                ></div>
                              }
                              readonly
                            />
                          </div>
                          <div className="font14 med ms-1 primary-text">
                            {data.rating}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
      <section className="common_sec">
        <div className="container">
          <div className="heading_block">
            <h6 className="mb-0 fontPrimary2 text-dark">
              Relevant to this product
            </h6>
          </div>
        </div>
        <div className="ps-4">
          <div className="bestseller_slider">
            <div className="container">
              <Swiper
                spaceBetween={15}
                slidesPerView={5.3}
                loop={false}
                modules={[Mousewheel]}
                breakpoints={{
                  1299: {
                    slidesPerView: 4.8,
                  },
                  992: {
                    slidesPerView: 4.3,
                  },
                  768: {
                    slidesPerView: 3.3,
                  },
                  575: {
                    slidesPerView: 2.3,
                  },
                }}
              >
                {relevantProducts &&
                  relevantProducts.map((opt, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <Product data={opt} color={color} />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      {/* {!isLogIn().isToken && searchParams.get("type") ? (
        <>
          {searchParams.get("type") == "set-password" ? (
            <SetPassword show={open} handleClick={handleClick} />
          ) : searchParams.get("type") == "signup" ? (
            <Signup
              show={open}
              handleClick={handleClick}
              handleClose={handleCloseNew}
            />
          ) : searchParams.get("type") == "forgot" ? (
            <ForgotPassword show={open} handleClick={handleClick} />
          ) : (
            <Login
              show={open}
              handleClose={handleCloseNew}
              handleClick={handleClick}
            />
          )}
        </>
      ) : null} */}
      <Modal
        show={showModalNew}
        onHide={handleClose}
        centered
        size="md"
        className={
          modelType === "topChart" ||
          modelType === "bottomChart" ||
          modelType === "sizeChart"
            ? "sizeModal"
            : "customizeModal"
        }
      >
        {modelType === "customize" ? (
          <Modal.Header closeButton className="">
            <Modal.Title>
              <h5 className="mb-0 font20 fontPrimary2">
                Get custom embroidery
              </h5>
            </Modal.Title>
          </Modal.Header>
        ) : null}
        {modelType === "topChart" ||
        modelType === "bottomChart" ||
        modelType === "sizeChart" ? (
          <div className="modal-header customHeader">
            <div className="customHeader__top ">
              <div className="modal-title h4">
                <h5 className="mb-0 font20 fontPrimary2 text-dark">
                  {modelType === "topChart"
                    ? productData?.detail?.sizecharttop?.[0]?.size_chart_title
                    : ""}
                  {modelType === "bottomChart"
                    ? productData?.detail?.sizechartbottom?.[0]
                        ?.size_chart_title
                    : ""}
                  {modelType === "sizeChart"
                    ? productData?.detail?.sizechart?.[0]?.size_chart_title
                    : ""}
                </h5>
              </div>
              <button
                onClick={handleClose}
                type="button"
                className="btn-close"
                aria-label="Close"
              ></button>
            </div>
            <p className="font14 reg fontPrimary2">
              As per body measurements in inches
            </p>
          </div>
        ) : (
          ""
        )}
        <Modal.Body>
          {modelType === "topChart" ? (
            <div
              style={{ display: "flex", alignItems: "flex-start", gap: "20px" }}
            >
              {productData?.detail?.sizecharttop?.[0]?.size_chart_image && (
                <div style={{ flex: "0 0 200px" }}>
                  <img
                    src={`${API_URL}/public${productData?.detail?.sizecharttop?.[0]?.size_chart_image}`}
                    alt="Size Chart"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              )}
              <Table bordered className="sizeTable" style={{ flex: "1" }}>
                <thead>
                  <tr>
                    <th className="font14 reg text-dark">Size</th>
                    <th className="font14 reg text-dark">Chest</th>
                    <th className="font14 reg text-dark">Shoulder</th>
                    <th className="font14 reg text-dark">Length</th>
                  </tr>
                </thead>
                <tbody>
                  {productData &&
                  productData?.detail?.sizecharttop &&
                  productData?.detail?.sizecharttop?.length > 0
                    ? productData?.detail?.sizecharttop[0]?.sizes.map(
                        (data, index) => (
                          <tr key={index}>
                            <td>{data.title}</td>
                            <td>{data.chest}</td>
                            <td>{data.shoulder}</td>
                            <td>{data.length}</td>
                          </tr>
                        )
                      )
                    : null}
                </tbody>
              </Table>
            </div>
          ) : null}
          {modelType === "bottomChart" ? (
            <div
              style={{ display: "flex", alignItems: "flex-start", gap: "20px" }}
            >
              {productData?.detail?.sizechartbottom?.[0]?.size_chart_image && (
                <div style={{ flex: "0 0 200px" }}>
                  <img
                    src={`${API_URL}/public${productData?.detail?.sizechartbottom?.[0]?.size_chart_image}`}
                    alt="Size Chart"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              )}
              <Table bordered className="sizeTable" style={{ flex: "1" }}>
                <thead>
                  <tr>
                    <th className="font14 reg text-dark">Size</th>
                    <th className="font14 reg text-dark">Waist</th>
                    <th className="font14 reg text-dark">Hips</th>
                    <th className="font14 reg text-dark">Length</th>
                  </tr>
                </thead>
                <tbody>
                  {productData &&
                  productData?.detail?.sizechartbottom &&
                  productData?.detail?.sizechartbottom?.length > 0
                    ? productData?.detail?.sizechartbottom[0]?.sizes.map(
                        (data, index) => (
                          <tr key={index}>
                            <td>{data.title}</td>
                            <td>{data.waist}</td>
                            <td>{data.hips}</td>
                            <td>{data.length}</td>
                          </tr>
                        )
                      )
                    : null}
                </tbody>
              </Table>
            </div>
          ) : null}
          {modelType === "sizeChart" ? (
            <div
              style={{ display: "flex", alignItems: "flex-start", gap: "20px" }}
            >
              {productData?.detail?.sizechart?.[0]?.size_chart_image && (
                <div style={{ flex: "0 0 200px" }}>
                  <img
                    src={`${API_URL}/public${productData?.detail?.sizechart?.[0]?.size_chart_image}`}
                    alt="Size Chart"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              )}
              <Table bordered className="sizeTable" style={{ flex: "1" }}>
                <thead>
                  <tr>
                    <th className="font14 reg text-dark">Size</th>
                    <th className="font14 reg text-dark">Chest</th>
                    <th className="font14 reg text-dark">Shoulder</th>
                    <th className="font14 reg text-dark">Length</th>
                  </tr>
                </thead>
                <tbody>
                  {productData &&
                  productData?.detail?.sizechart &&
                  productData?.detail?.sizechart?.length > 0
                    ? productData?.detail?.sizechart[0]?.sizes.map(
                        (data, index) => (
                          <tr key={index}>
                            <td>{data.title}</td>
                            <td>{data.chest}</td>
                            <td>{data.shoulder}</td>
                            <td>{data.length}</td>
                          </tr>
                        )
                      )
                    : null}
                </tbody>
              </Table>
            </div>
          ) : null}

          {modelType === "customize" ? (
            <>
              <div className="selectionHolder d-flex customizeTab">
                {customTab.map((data, ind) => {
                  return (
                    <div key={ind} className="selectionHolder__box">
                      <input
                        checked={tab === data.id}
                        type="radio"
                        id={data.id}
                        name="tab"
                        onChange={() => changeTab(data.id, data.value)}
                      />
                      <label htmlFor={data.id}>{data.name}</label>
                    </div>
                  );
                })}
              </div>
              <div className="placementBox__holder">
                {tab === "name" || tab === "both" ? (
                  <div className="placementBox d-flex justify-content-between align-items-center">
                    <div className="font14 med text-light2">Name Placement</div>
                    <div className="font14 med text-dark">Left Chest</div>
                  </div>
                ) : null}
                {tab === "logo" || tab === "both" ? (
                  <div className="placementBox d-flex justify-content-between align-items-center">
                    <div className="font14 med text-light2">Logo Placement</div>
                    <div className="font14 med text-dark">Right Chest</div>
                  </div>
                ) : null}
              </div>

              {tab === "name" || tab === "both" ? (
                <>
                  <div className="customize__spacing">
                    <input
                      type="text"
                      className="formField w-100"
                      placeholder="Full Name (to be printed)"
                      name="text"
                      value={customization.text}
                      onChange={(e) => {
                        setCustomization({
                          ...customization,
                          text: e.target.value,
                        });
                      }}
                    />
                    <p className="text-danger" style={{ marginLeft: "8px" }}>
                      {errors.text}
                    </p>
                  </div>

                  <div className="customize__spacing">
                    <div className="produtPagTitle font14 med mb-2 d-flex">
                      Font color{" "}
                      <p className="text-danger" style={{ marginLeft: "8px" }}>
                        {errors.fontColor}
                      </p>
                    </div>
                    <div className="selectionHolder d-flex">
                      {custColors &&
                        custColors?.map((data, i) => {
                          return (
                            <div key={i} className="selectionHolder__box">
                              <input
                                type="radio"
                                id={data._id}
                                name="fontColor"
                                value={data.color_name}
                                onChange={(e) => {
                                  setCustomization({
                                    ...customization,
                                    fontColor: e.target.value,
                                  });
                                }}
                              />
                              <label htmlFor={data._id}>
                                <span
                                  className="color"
                                  style={{ background: data.color_hexacode }}
                                ></span>
                                <span className="name">{data.color_name}</span>
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="customize__spacing">
                    <div className="produtPagTitle font14 med mb-2 d-flex">
                      Font style{" "}
                      <p className="text-danger" style={{ marginLeft: "8px" }}>
                        {errors.fontStyle}
                      </p>
                    </div>
                    <div className="radioType2 d-flex flex-column">
                      {fontStyle.map((data, i) => {
                        return (
                          <div key={i} className="radioType2__box">
                            <input
                              type="radio"
                              id={data.id}
                              name="fontStyle"
                              value={data.name}
                              onChange={(e) => {
                                setCustomization({
                                  ...customization,
                                  fontStyle: e.target.value,
                                });
                              }}
                            />
                            <label htmlFor={data.id}>{data.name}</label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              ) : null}
              {tab === "logo" || tab === "both" ? (
                <>
                  <div className="imageUploadCstm customize__spacing">
                    <input
                      type="file"
                      id="logoUpload"
                      onChange={handleChange}
                    />
                    <label htmlFor="logoUpload">
                      <div className="imageUploadCstm__img">
                        <img src={images.addphoto} alt="" />
                      </div>
                      <div className="med font14 primary-text">
                        Upload your Logo here
                      </div>
                      <div className="font12 fontPrimary2">
                        JPEG or PNG, Minimum resolution 200px by 200px, Maximum
                        size 5MB
                      </div>
                    </label>
                  </div>
                  {customization.logoUrl && (
                    <div className="selectedLogoDetails customize__spacing d-flex justify-content-between align-items-center">
                      <div className="selectedLogoDetails__left d-flex align-items-center">
                        <div className="img">
                          {customization.logoUrl ? (
                            <img
                              src={URL.createObjectURL(customization.logoUrl)}
                              alt="Logo Preview"
                            />
                          ) : (
                            <p>No logo selected</p>
                          )}
                        </div>
                        <div className="content">
                          <div className="name font14 med text-dark">
                            My Logo.jpg
                          </div>
                        </div>
                      </div>
                      <ul className="selectedLogoDetails__right d-flex">
                        {/* <li>
                        <button>
                          <img src={images.upload} />
                        </button>
                      </li> */}
                        <li>
                          <button onClick={handleDelete}>
                            <img src={images.delete} alt="Delete Logo" />
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                </>
              ) : null}

              <p className="text-danger" style={{ marginLeft: "8px" }}>
                {errors.logoUrl}
              </p>

              <div className="customizePopInfo d-flex">
                <div className="customizePopInfo__icon">
                  <img src={images.info} className="w-100" alt="" />
                </div>
                <div className="customizePopInfo__cntnt font12 med">
                  Embroidered items are final sale. They can not be returned or
                  exchanged. Please note, embroidered items take upto 5 days to
                  dispatch.
                </div>
              </div>
            </>
          ) : null}
        </Modal.Body>
        {modelType === "customize" ? (
          <Modal.Footer>
            <div className="customizeFooter d-flex justify-content-between align-content-center w-100">
              <div className="customizeFooter__left d-flex align-items-center">
                <div className="font16 med">Amount</div>
                <div className="fontPrimary2 text-dark bold">
                  {tab === "name"
                    ? "₹ 50.00"
                    : tab === "logo"
                    ? "₹ 100.00"
                    : "₹ 150.00"}
                </div>
              </div>
              <button
                className="common_btn primary btn2"
                onClick={handleCustomizationSubmit}
              >
                Add
              </button>
            </div>
          </Modal.Footer>
        ) : null}
      </Modal>
      <ToastContainer />
    </>
  );
}
