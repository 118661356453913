import React from "react";
import images from "../../images";
import { NavLink } from "react-router-dom";

export default function Footer() {
  const menu = [
    {
      name: "Support",
      nav: [
        { name: "About Us", link: "/about-us" },
        { name: "Refund Policy", link: "/refund-policy" },
        { name: "Terms & Conditions", link: "/terms-and-condition" },
        { name: "Privacy Policy", link: "/privacy-policy" },
        { name: "Shipping Policy", link: "/shipping-policy" },
      ],
    },
  ];
  return (
    <footer className="common_sec">
      <div className="container">
        <div className="mb-4 footer_logo">
          <img src={images.logo_white} alt="" width={270} />
        </div>
        <div className="footer_nav row gy-4">
          {menu.map((opt, i) => {
            return (
              <div className="col-lg-3 col-md-4" key={i}>
                <p className="font16 med mb-3">{opt.name}</p>
                <ul>
                  {opt.nav.map((x) => {
                    return (
                      <li key={x.name}>
                        <NavLink to={x.link}>{x.name}</NavLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
          <div className="col-lg-3 col-md-12">
            <p className="font16 med mb-3">Contact</p>
            <p className="font14 med mb-1">+91 9819755883 / +91 9819755881</p>
            <p className="font14 med">
              <a href="mailto:info@medilinensolutions.com">
                info@medilinensolutions.com
              </a>
            </p>
            <p className="font14 med mb-1">
              {" "}
              <a
                href="https://wa.me/9819755883"
                class="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa fa-whatsapp whatsapp-icon"></i> WhatsApp
              </a>
            </p>
          </div>
          <div className="col-lg-6 col-md-12 d-flex">
            <div>
              <span style={{ maxWidth: "00px", color: "#FFC794" }}>
                Registered Office:
              </span>
              <p style={{ maxWidth: "600px", color: "#FFC794" }}>
                SMITA CHS. DR. AMBEDKAR ROAD, MULUND WEST.
              </p>
            </div>
            <div>
              <span style={{ maxWidth: "600px", color: "#FFC794" }}>
                Sales Office:
              </span>
              <p style={{ maxWidth: "600px", color: "#FFC794" }}>
                JAI AMBE COMPLEX, 203, 2 ND FLOOR VAL VILLAGE, DADOBA COMPOUND,
                BHIWANDI - 421302
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-2 d-flex justify-content-between align-items-center">
        <div>
          <p style={{ fontSize: "12px" }}>
            Copyright {new Date().getFullYear()}. All Rights Reserved. Medilinen
            Solutions
          </p>
        </div>
        <div>
          <p style={{ fontSize: "12px" }}>
            <a
              href="https://www.woodapples.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Proudly Designed and Developed by Woodapple Software Solutions Pvt
              Ltd
            </a>
          </p>
        </div>
      </div>
      <style>
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
        />
      </style>
    </footer>
  );
}
