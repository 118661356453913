import React from "react";

export default function PrivacyPolicy() {
  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>Privacy Policy</h3>
      <div style={styles.content}>
        <p style={styles.sectionTitle}>Introduction</p>
        <p>
          Welcome to Medilinen. We are committed to protecting and respecting
          your privacy. This Privacy Policy explains how we collect, use,
          disclose, and safeguard your information when you visit our website
          https://medilinensolutions.com/, whether accessed via computer, mobile
          device, or other technology.
        </p>
        <p style={styles.sectionTitle}>Information We Collect</p>
        <p>We may collect and process the following data about you:</p>
        <p style={styles.sectionTitle}>Information You Provide to Us</p>
        <ul style={styles.list}>
          <li>
            Personal Information: We may collect personal information such as
            your name, email address, phone number, and any other information
            you voluntarily provide to us when you fill out forms on our
            website, subscribe to our newsletter, or communicate with us.
          </li>
        </ul>
        <p style={styles.sectionTitle}>Information Collected Automatically</p>
        <ul style={styles.list}>
          <li>
            Log Data: Our servers automatically record information ("log data")
            created by your use of the website. Log data may include information
            such as your IP address, browser type, the referring domain, pages
            visited, and search terms.
          </li>
          <li>
            Cookies and Similar Technologies: We use cookies to collect
            information about your use of our website. Cookies are small data
            files stored on your device that allow us to recognize you when you
            return to our site. You can set your browser to refuse all cookies
            or to indicate when a cookie is being sent. However, some features
            of our site may not function properly without cookies.
          </li>
        </ul>
        <p style={styles.sectionTitle}>How We Use Your Information</p>
        <ul style={styles.list}>
          <li>
            To Provide and Maintain Our Service: To provide and maintain our
            website, including to monitor the usage of our website.
          </li>
          <li>
            To Communicate with You: To respond to your inquiries, comments,
            feedback, or questions.
          </li>
          <li>
            To Improve Our Website: To analyze and improve the functionality and
            user experience of our website.
          </li>
          <li>
            To Send Periodic Emails: We may use the email address you provide to
            send you information and updates pertaining to your order or
            inquiry, as well as occasional company news, updates, or related
            product or service information.
          </li>
        </ul>
        <p style={styles.sectionTitle}>How We Share Your Information</p>
        <ul style={styles.list}>
          <li>
            Service Providers: We may share your information with third-party
            service providers who perform services on our behalf, such as
            website hosting, data analysis, and customer service.
          </li>
          <li>
            Legal Requirements: We may disclose your information if required to
            do so by law or in response to valid requests by public authorities
            (e.g., a court or a government agency).
          </li>
        </ul>
        <p style={styles.sectionTitle}>Data Security</p>
        <p>
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse.
        </p>
        <p style={styles.sectionTitle}>Your Rights</p>
        <ul style={styles.list}>
          <li>
            Access: You have the right to request access to the personal
            information we hold about you.
          </li>
          <li>
            Correction: You have the right to request that we correct any
            inaccuracies in your personal information.
          </li>
          <li>
            Deletion: You have the right to request that we delete your personal
            information under certain circumstances.
          </li>
          <li>
            Restriction: You have the right to request that we restrict the
            processing of your personal information under certain circumstances.
          </li>
          <li>
            Data Portability: You have the right to receive a copy of your
            personal information in a structured, commonly used, and
            machine-readable format.
          </li>
        </ul>
        <p>
          To exercise any of these rights, please contact us using the contact
          information provided below.
        </p>
        <p style={styles.sectionTitle}>Third-Party Links</p>
        <p>
          Our website may contain links to third-party websites. We do not
          control and are not responsible for the content or practices of these
          third-party websites. We encourage you to review the privacy policies
          of any third-party websites you visit.
        </p>
        <p style={styles.sectionTitle}>Changes to This Privacy Policy</p>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on this page. You
          are advised to review this Privacy Policy periodically for any
          changes.
        </p>
        <p style={styles.sectionTitle}> Delete Your Account - Medilinen</p>
        <p>
          Welcome to the account deletion page for Medilinen. If you would like
          to delete your account, please follow the steps below. Steps to
          Request Account Deletion Log in to your Medilinen account. Navigate to
          the account settings page. Click on the "Delete Account" button.
          Confirm your request by following the on-screen instructions. Types of
          Data Deleted When you request to delete your account, the following
          data will be permanently deleted.
        </p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  content: {
    fontSize: "16px",
  },
  sectionTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "20px",
  },
  list: {
    marginLeft: "20px",
    marginTop: "10px",
    listStyleType: "disc",
  },
};
