import React from "react";

export default function RefundPolicy() {
  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>Refund Policy</h3>
      <div style={styles.content}>
        <p style={styles.sectionTitle}>Hassle-Free Returns & Exchanges</p>
        <p>
          We are committed to providing a seamless shopping experience. We offer
          a 7-day hassle-free return and exchange policy from the date of
          delivery. Refunds are initiated within 5 days after the return items
          are picked up and passed quality checks. Please note that embroidery
          orders are not eligible for returns and exchanges.
        </p>
        <p style={styles.sectionTitle}>Prepaid Orders</p>
        <p>
          For prepaid orders, a reverse shipment fee of FLAT Rs 100 per order
          will be deducted. The deduction applies only to returns, not
          exchanges.
        </p>
        <p style={styles.sectionTitle}>COD Orders</p>
        <p>
          For COD (Cash on Delivery) orders, store credits will be provided in
          the form of a Medilinen gift card, sent to your registered email
          address. Please note that COD handling charges paid at the time of
          placing the order are non-refundable.
        </p>
        <p style={styles.sectionTitle}>Processing Returns</p>
        <ul style={styles.list}>
          <li>After the items are picked up, they undergo quality checks.</li>
          <li>
            Refunds are initiated within 5 days after quality checks are
            completed.
          </li>
          <li>
            If our reverse pickup service is not available at your location, we
            request you to self-ship the product via any reliable courier
            service. We will process your refund after the items are delivered
            to our warehouse. (Warehouse address to be provided upon request)
          </li>
        </ul>
        <p style={styles.sectionTitle}>Processing Exchanges</p>
        <ul style={styles.list}>
          <li>
            An exchange order will be initiated once the old order is picked up.
          </li>
          <li>
            If our reverse pickup service is not available at your location, we
            request you to self-ship the product via any reliable courier
            service. We will ship the exchange product to you after the items
            are delivered to our warehouse. (Warehouse address to be provided
            upon request)
          </li>
        </ul>
        <p style={styles.footer}>
          Thank you for shopping with us! If you have any questions, please
          contact our customer service team at{" "}
          <span style={styles.contact}>info@medilinensolutions.com</span> or
          Contact us on 9819755883 / 9819755881
        </p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  content: {
    fontSize: "16px",
  },
  sectionTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "20px",
  },
  list: {
    marginLeft: "20px",
    marginTop: "10px",
    listStyleType: "disc",
  },
  footer: {
    marginTop: "20px",
    fontSize: "14px",
  },
  contact: {
    color: "#007bff",
  },
};
