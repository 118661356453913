import React, { useState } from "react";
import images from "../images";
import { useCookies } from "react-cookie";
import { loginUser } from "../API/fakebackend";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

export default function Login(props) {
  const url = useLocation();
  const [cookies] = useCookies(["device_id"]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const login = async (event) => {
    event.preventDefault();
    const values = {
      email: email.trim() || "",
      password: password.trim() || "",
      device_id: cookies.device_id || "",
    };

    try {
      const response = await loginUser(values);

      if (response.result === 1) {
        localStorage.setItem("authUser", JSON.stringify(response));
        navigate(url.pathname);
      } else {
        toast.error(response.data.msg, { autoClose: 2000 });
      }
    } catch (error) {
      console.error("Login Error:", error);
      toast.error(error.response.data.msg, {
        autoClose: 2000,
      });
    }
  };

  return (
    <div className={`login_modal ${props.show ? "show" : ""}`}>
      <div className="login_modal_container">
        <div className="login_modal_header">
          <img src={images.logo} alt="" width={200} />
          <button onClick={props.handleClose}>
            <img src={images.close} alt="" width={14} />
          </button>
        </div>
        <div className="login_modal_form">
          <h4 className="font20 text-dark bold fontPrimary2 mb-4">
            Login your account
          </h4>
          <form onSubmit={login}>
            <div className="mb-2">
              <input
                type="email"
                className="formField"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="mb-2 d-flex">
              <input
                type={showPassword ? "text" : "password"}
                className="formField w-100"
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <button
                type="button"
                className="right_btn ms-2"
                onClick={() => setShowPassword(!showPassword)}
              >
                <img
                  src={
                    !showPassword ? images.visibility : images.visibility_off
                  }
                  alt=""
                />
              </button>
            </div>
            <div className="login_modal_header mt-4 align-items-center">
              <button
                type="button"
                className="primary-text font14 med"
                onClick={() => props.handleClick("forgot")}
              >
                Forgot Password?
              </button>
              <button type="submit" className="common_btn primary btn2">
                Login
              </button>
            </div>
            <div className="">
              <button
                type="button"
                className="common_btn outlined w-100 med font14 rounded"
                onClick={() => props.handleClick("signup")}
              >
                Sign up now
              </button>
              {/* <div className="or_text">
                <span>or</span>
              </div>
              <button className="common_btn outlined w-100 med mb-3 font14 text-dark rounded">
                <img src={images.google} alt="" /> Sign in with Google
              </button>
              <button className="common_btn outlined w-100 med font14 text-dark rounded">
                <img src={images.fb_btn} alt="" /> Continue with Facebook
              </button> */}
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
